export const JWT_KEY = "erp-token";
export const REFRESH_JWT_KEY = "erp-refresh-token";
export const REFRESH_JWT_API_PATH = "/api/auth/jwt/refresh/";
export const TABLE_LIST_LIMIT = 50;
export const MONTHS_LIST_LIMIT = 24;
export const PROJECTS_STATUSES = [
  "start",
  "development",
  "completion",
  "completed",
  "stopped",
];
export const PROJECTS_TYPES = {
  production: [
    { name: "Производственные", id: "true" },
    { name: "Непроизводственные", id: "false" },
  ],
  commercial: [
    { name: "Коммерческие", id: "true" },
    { name: "Некоммерческие", id: "false" },
  ],
};
export const QUALIFICATIONS_LIST = ["lead", "senior", "middle", "junior"];
export const DEFAULT_TITLE = "ERP";
export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_FOR_FLAT_PICKR = "d.m.Y";
export const DATE_ALT_FORMAT_FOR_FLAT_PICKR = "Y-m-d";
export const MONTHS = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];
export const WEEK_DAYS_FULL = [
  "понедельник",
  "вторник",
  "среда",
  "четверг",
  "пятница",
  "суббота",
  "воскресенье",
];
export const WEEK_DAYS_SHORT = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
export const EXPENSES_TABLE_FIRST_COLUMN_WIDTH =
  "EXPENSES_TABLE_FIRST_COLUMN_WIDTH";
