import Vue from "vue";

Vue.filter("capitalize", (value) => {
  if (!value) return "";
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
});

Vue.filter("noData", (value) => {
  if (typeof value !== "number") {
    return 0;
  } else {
    return value;
  }
});
