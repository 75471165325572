import api from "../api";

let contextTicket = false;

export default {
  namespaced: true,
  state: {
    myCalendar: null,
    contextOfPtojects: [],
    employeeProductionCalendar: [],
    listForEditing: [],
  },
  getters: {
    getEmployeesInProjects(state) {
      const result = [];
      state.contextOfPtojects.forEach((customer) => {
        customer.projects?.forEach((project) => {
          const staff = [];
          const addedIds = [];
          project.assignments.forEach((assignment) => {
            assignment.sub_assignments.map(({ profile }) => {
              if (!addedIds.includes(profile.id)) {
                staff.push({
                  id: profile.id,
                  first_name: profile.first_name,
                  last_name: profile.last_name,
                  months: profile.months,
                });
                addedIds.push(profile.id);
              }
            });
          });
          result.push({
            id: project.id,
            name: customer.name + " - " + project.name,
            nameForSort: project.name,
            staff,
          });
        });
      });
      return result
        .filter((group) => group.staff.length)
        .sort((a, b) => {
          const nameA = a.nameForSort.toLowerCase();
          const nameB = b.nameForSort.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
    },
    getProjectsList(state, getters) {
      return getters.getEmployeesInProjects.map((project) => {
        return {
          id: project.id,
          name: project.name,
        };
      });
    },
  },
  mutations: {
    setCalendar(state, value) {
      state.calendar = value;
    },
    setAvailability(state, value) {
      state.availability = value;
    },
    setChangeableMonthId(state, id) {
      state.changeableMonthId = id;
    },
    setMyCalendar(state, data) {
      state.myCalendar = data;
    },
    setContextOfProjects(state, data) {
      state.contextOfPtojects = data;
    },
    setEmployeeProductionCalendar(state, data) {
      state.employeeProductionCalendar = data;
    },
    setListForEditing(state, data) {
      state.listForEditing = data;
    },
  },
  actions: {
    async loadMyCalendar({ commit, dispatch }, params) {
      try {
        const { results } = await api.employeeCalendar.get(params);
        commit("setMyCalendar", results);
        return { success: true, results };
      } catch (e) {
        console.error(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение данных по личным календарям`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
        return { success: false, details: e };
      }
    },
    async loadContextOfPtojects({ commit, dispatch }, monthId) {
      try {
        if (contextTicket) {
          return;
        }
        contextTicket = api.employeeCalendar.contextOfPtojects(monthId);
        const { results } = await contextTicket;
        commit("setContextOfProjects", results);
        contextTicket = false;
      } catch (e) {
        console.error("loadContextOfPtojects", e);
        contextTicket = false;
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение данных календарям в разрезе планирований`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async updateCalendar({ dispatch }, params) {
      try {
        await api.employeeCalendar.update(params);
        return { ok: true };
      } catch (e) {
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Обновление типа дня`,
            message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
          },
          { root: true }
        );
        return {
          ok: false,
          details: e,
        };
      }
    },
    async loadEmployeeProductionCalendar({ commit, dispatch }) {
      try {
        const { results } =
          await api.employeeCalendar.getEmployeeProductionCalendar();
        commit("setEmployeeProductionCalendar", results);
        return { ok: true };
      } catch (e) {
        console.error(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение данных по производственному календарю`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
        return { ok: false, details: e };
      }
    },
    async loadListForEditing({ commit, dispatch }, params) {
      try {
        const { results } = await api.employeeCalendar.getListForEditing(
          params
        );
        commit("setListForEditing", results);
        return { ok: true };
      } catch (e) {
        console.error(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка сотрудников доступных для редактирования`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
        return { ok: false, details: e };
      }
    },
  },
};
