<template>
  <div class="modal" v-show="isOpen">
    <div @click="onClose" class="modal__overlay">
      <div @click.stop class="modal__wrapper">
        <div class="modal__header">{{ title }}</div>
        <div class="modal__body">
          <slot></slot>
        </div>
        <div class="modal__actions">
          <div class="modal__action-button">
            <Button theme="modal" @click="cancel">Отменить</Button>
          </div>
          <div class="modal__action-button">
            <Button theme="modal" @click="accept">Применить</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  components: { Button },
  props: {
    isOpen: {
      type: [Boolean, Object],
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onAccept: {
      type: Function,
    },
    title: {
      type: String,
    },
  },
  methods: {
    accept() {
      if (this.onAccept) {
        this.onAccept();
      }
    },
    cancel() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";
</style>
