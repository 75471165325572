<template>
  <div class="erm-container">
    <Navbar />
    <Content />
    <ChangeFieldModal />
    <InfoModal
      v-for="message of messagesDataList"
      :key="message.id"
      :data="message"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import Content from "@/components/Content";
import ChangeFieldModal from "@/components/modal/ChangeFieldModal";
import InfoModal from "@/components/modal/InfoModal";

export default {
  name: "main-layout",
  components: {
    Navbar,
    Content,
    ChangeFieldModal,
    InfoModal,
  },
  computed: {
    ...mapState({
      messagesSet: (state) => state.messagesStore.messagesSet,
      messagesUpdateNumber: (state) => state.messagesStore.messagesUpdateNumber,
    }),
    messagesDataList() {
      return this.messagesUpdateNumber && this.messagesSet.toArray();
    },
  },
};
</script>

<style lang="scss">
.erm-container {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #2c3e50;
  min-height: 100vh;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-template-areas: "navbar content";
}
.container {
  padding: 20px 10px 150px;
}
</style>
