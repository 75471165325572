import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Reports {
  constructor() {
    Binder.bind(this, Reports);
    this.apiService = new ApiService("/api/reports");
  }

  projectReport(month_id, project_id) {
    return this.apiService.get(
      "project-bookkeeper-report",
      { month_id, project_id },
      {},
      true,
      "",
      true,
      true
    );
  }

  allProjectsReport(month_id) {
    return this.apiService.get(
      "project-bookkeeper-reports",
      { month_id },
      {},
      true,
      "",
      true,
      true
    );
  }

  pmProjectReport(since, until, project_id) {
    return this.apiService.get(
      "project-pm-report",
      { since, until, project_id },
      {},
      true,
      "",
      true,
      true
    );
  }

  consolidatedReport(month_id) {
    return this.apiService.get(
      "projects-summary-report",
      { month_id },
      {},
      true,
      "",
      true,
      true
    );
  }
}

export default new Reports();
