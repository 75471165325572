import api from "../api";
import dayjs from "dayjs";

const productionCalendarStore = {
  namespaced: true,
  state: {
    productionCalendar: {},
    currentDaysCalendar: [],
    allDaysCalendar: [],
    todayId: 0,
    calendarYears: [],
    calendarMonths: [],
    serverTime: null,
  },
  getters: {
    todayId(state) {
      const todayNumber = dayjs().day();
      let newTodayId = 0;
      if (state.currentDaysCalendar) {
        newTodayId = state.currentDaysCalendar.find(
          (day) => day.number === todayNumber
        )?.id;
      }
      return newTodayId ? newTodayId : 0;
    },
    getCalendarYears(state) {
      return state.calendarYears;
    },
    getCalendarMonths(state) {
      return state.calendarMonths;
    },
    getCalendarHash(state) {
      return state.currentDaysCalendar.reduce((result, day) => {
        result[`${day.month.name}-${day.number}`] = day;
        return result;
      }, {});
    },
  },
  mutations: {
    setProductionCalendar(state, { id, response }) {
      state.productionCalendar[id] = response;
    },
    setCalendarDays(state, { response }) {
      const allDaysCalendar = [...state.allDaysCalendar];
      const newDays = [];
      response.results.forEach((newDay) => {
        if (!allDaysCalendar.find((day) => day.id === newDay.id)) {
          newDays.push(newDay);
        }
      });
      if (newDays.length) {
        state.allDaysCalendar.push(...newDays);
      }
      state.currentDaysCalendar = response.results;
    },
    setCalendarYears(state, { response }) {
      state.calendarYears = response.results;
    },
    setCalendarMonths(state, { response }) {
      state.calendarMonths = response.results;
    },
    setServerTime(state, time) {
      state.serverTime = time;
    },
  },
  actions: {
    async loadProductionCalendar({ commit, dispatch }, { id }) {
      try {
        const response = await api.productionCalendar.getOneMonth({ id });
        commit("setProductionCalendar", { id, response });
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение информации о месяце`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadCalendarDays({ commit, dispatch }, params = {}) {
      try {
        const response = await api.productionCalendar.getDays(params);
        commit("setCalendarDays", { response });
      } catch (e) {
        console.log(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка дней`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadCalendarMonths({ commit, dispatch }, year_id) {
      try {
        const response = await api.productionCalendar.getMonths({ year_id });
        console.log("loadCalendarMonths: ", response);
        commit("setCalendarMonths", { response });
      } catch (e) {
        console.log(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение месяцев`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadCalendarYears({ commit, dispatch }) {
      try {
        const response = await api.productionCalendar.getYears({ limit: 10 });
        commit("setCalendarYears", { response });
      } catch (e) {
        console.log(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка лет`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadServerTime({ commit, dispatch }) {
      try {
        const response = await api.productionCalendar.getServerTime();
        commit("setServerTime", response.server_time.replace(/\..*/, ""));
      } catch (e) {
        console.error("loadServerTime", e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение текущего времени сервера`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
  },
};

export default productionCalendarStore;
