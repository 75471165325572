import { getLocalStorage, setToken } from "../assets/utils";
import { REFRESH_JWT_KEY, REFRESH_JWT_API_PATH } from "../assets/constants";

async function interceptor(
  { status, result, response, isBlobType },
  method,
  url,
  params,
  body,
  headers
) {
  if (status < 400) {
    return result;
  }

  // Обработка ошибки со статусом 40х, 50х
  const error = new Error(response.statusText);
  error.code = status || "";
  error.data = response;
  error.result = result;

  const isBadAuth = result.error_code === "authentication_failed";

  if (status !== 401 || isBadAuth) {
    throw error;
  } else if (status === 401 && !this.isRepeated) {
    this.isRepeated = true;
    const refreshJWT = getLocalStorage(REFRESH_JWT_KEY);
    if (!refreshJWT) {
      throw error;
    }
    // обновляем токен
    const res = await fetch(REFRESH_JWT_API_PATH, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshJWT }),
    });

    if (res.status < 400) {
      this.isRepeated = false;
    } else {
      throw error;
    }

    let result = null;
    result = await res.text();
    result = result ? JSON.parse(result) : {};
    setToken(result.access);
    headers["Authorization"] = `Bearer ${result.access}`;
    // повторяем запрос, не прошедший из-за неактуального токена
    return await this.request(
      method,
      url,
      params,
      body,
      headers,
      true,
      "",
      null,
      false,
      isBlobType
    );
  } else if (this.isRepeated) {
    throw error;
  }
}

export default interceptor;
