<template>
  <main class="content">
    <router-view />
  </main>
</template>

<script>
export default {
  name: "ErpContent",
};
</script>

<style lang="scss">
.content {
  grid-area: content;
  width: auto;
  background-color: #f5f5f5;
}
</style>
