import ApiService from "../apiService";
import Binder from "../../assets/binder";

class EmployeeCalendar {
  constructor() {
    Binder.bind(this, EmployeeCalendar);
    this.apiService = new ApiService("/api/employee-calendar");
  }
  get(params) {
    return this.apiService.get("", params);
  }

  contextOfPtojects(monthId) {
    return this.apiService.get("employee-planning-calendar", {
      month_id: monthId,
    });
  }

  update(data) {
    return this.apiService.patch("days", {}, data);
  }

  getEmployeeProductionCalendar() {
    return this.apiService.get("employee-production-calendar");
  }

  getListForEditing(params) {
    return this.apiService.get("editable", { limit: 0, ...params });
  }
}

export default new EmployeeCalendar();
