import { JWT_KEY, REFRESH_JWT_KEY } from "./constants";
import errorsDictionary from "./errorsDictionary";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function getLocalStorage(name) {
  const localStorageValue = window.localStorage.getItem(name);
  if (localStorageValue) {
    return localStorageValue;
  }
  return null;
}

function setToken(token) {
  window.localStorage.setItem(JWT_KEY, token);
}

function setRefreshToken(refreshToken) {
  window.localStorage.setItem(REFRESH_JWT_KEY, refreshToken);
}

function removeToken() {
  window.localStorage.removeItem(JWT_KEY);
  window.localStorage.removeItem(REFRESH_JWT_KEY);
}

function fieldNameByObject(fieldObject) {
  return Object.keys(fieldObject).find((item) => item !== "id");
}

function isNameMatch(query) {
  const firstName = query.first_name?.toLowerCase();
  const lastName = query.last_name?.toLowerCase();
  const inputText = query.input_text?.toLowerCase();
  return (
    lastName.includes(inputText) ||
    firstName.includes(inputText) ||
    `${lastName} ${firstName}`.includes(inputText) ||
    `${firstName} ${lastName}`.includes(inputText)
  );
}

function getDateFromPlanning(date, format = "string") {
  const monthNumber = date.name;
  const yearNumber = date.year.number;
  if (format === "string") {
    return `${yearNumber}-${monthNumber}`;
  }
  if (format === "array") {
    return [yearNumber, monthNumber];
  }
  if (format === "object") {
    return { year: yearNumber, month: monthNumber };
  }
  return null;
}

function getErrors(error) {
  let errorMessages = [];
  if (error?.result.message) {
    const message = error.result.message.replace(/\[|\]/g, "").split("', ");
    if (typeof message === "string") {
      errorMessages = [errorMessages];
    }
    message.forEach((msg) => {
      errorMessages.push(errorsDictionary[msg.replaceAll("'", "")]);
    });
  }
  return errorMessages;
}

function thisIsProd() {
  return location.hostname.startsWith("erp.satvaspace.tech");
}

function isObjectEmpty(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

function removeObjectKeys(targetObject, keysArray) {
  const result = {};
  Object.entries(targetObject).forEach(([key, value]) => {
    if (!keysArray.includes(key)) {
      result[key] = value;
    }
  });
  return result;
}

function debounce(fn, ms = 500) {
  let timeoutId;
  const pending = [];
  return (...args) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentPending = [...pending];
        pending.length = 0;
        Promise.resolve(fn.apply(this, args)).then(
          (data) => {
            currentPending.forEach(({ resolve }) => resolve(data));
          },
          (error) => {
            currentPending.forEach(({ reject }) => reject(error));
          }
        );
      }, ms);
      pending.push({ resolve: res, reject: rej });
    });
}

function throttle(func, ms) {
  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}

function numberToTime(number) {
  if (typeof number !== "number") {
    return "+00:00";
  }
  let [hours, minutes] = number.toString().split(".");
  if (hours.length === 1) {
    hours = hours.toString().length === 1 ? "0" + hours : hours;
    hours = number >= 0 ? "+" + hours : "-" + hours;
  }
  if (!minutes) {
    minutes = "00";
  } else {
    minutes = minutes * 60;
    minutes =
      minutes.toString().length === 1
        ? minutes + "0"
        : minutes.toString().substring(0, 2);
  }
  return hours + ":" + minutes;
}

function daysAYear(year) {
  if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
    return 366;
  } else {
    return 365;
  }
}

const clamp = (startCallback, endCallback, maxLines = 2) => {
  // при помощи колбеков можно менять состояние флага загрузки в компоненте и показывать в это время лоадер
  return (element, originText) => {
    if (startCallback) {
      startCallback();
    }
    return new Promise((resolve) => {
      if (!element) {
        return;
      }

      element.innerText = originText;
      const elemStyle = getComputedStyle(element);
      const fontSize = parseFloat(elemStyle.fontSize);
      let lineHeight = elemStyle.lineHeight;

      if (lineHeight === "normal") {
        lineHeight = fontSize * 1.6;
      } else if (lineHeight > fontSize) {
        lineHeight = lineHeight * fontSize;
      } else {
        lineHeight = parseFloat(lineHeight);
      }

      const maxHeight = lineHeight * maxLines;
      let isChanged = false;

      if (element.offsetHeight > maxHeight) {
        isChanged = true;
        let lastWordIndex = null;

        while (element.offsetHeight > maxHeight) {
          lastWordIndex = Math.max(element.innerText.lastIndexOf(" "), 0);
          element.innerText = element.innerText.slice(0, lastWordIndex);
        }

        while (lastWordIndex !== null && element.offsetHeight <= maxHeight) {
          const textNode = document.createTextNode(originText[lastWordIndex]);
          element.appendChild(textNode);
          lastWordIndex += 1;
        }

        element.innerText = element.innerText.slice(0, -3) + "…";
      }

      if (endCallback) {
        endCallback();
      }
      resolve(isChanged);
    });
  };
};

function isNumber(x) {
  return !isNaN(parseFloat(x)) && isFinite(x);
}

function convertMinutesToHours(minutes) {
  if (!minutes || minutes === 0) {
    return 0;
  }

  let hours = minutes / 60;

  if (hours <= 0.1) {
    hours = 0.1;
  } else {
    hours = Math.round(hours * 10) / 10;
  }

  return hours.toString();
}

function convertHoursToMinutes(hours) {
  if (!hours || hours === 0) {
    return 0;
  }

  let minutes = hours * 60;
  minutes = Math.round(minutes * 10) / 10;

  return minutes.toString();
}

// композиция функций с целью поэтапной обработки значения
// порядок применения функций слева направо
const pipe =
  (...fns) =>
  (value, context) => {
    if (fns.length === 0) {
      return value;
    }
    let result = value;
    for (const fn of fns) {
      result = fn(result, context);
    }
    return result;
  };

const calcElemCoords = (elem, coordsHandler) => {
  const node = elem instanceof Element ? elem : document.querySelector(elem);
  const coords = node.getBoundingClientRect();
  return coordsHandler ? coordsHandler(coords, node) : coords;
};

const group = (array, callback) => {
  const map = new Map();
  for (const element of array) {
    const key = callback(element);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key).push(element);
  }
  return Array.from(map, ([, value]) => value);
};

const retrier = (
  callback,
  { maxRetries = 3, failHandler, successHandler, delay }
) => {
  const fn = async (context) => {
    fn.state = "pending";
    let retries = 0;
    let result;
    while (retries < maxRetries) {
      try {
        result = await callback(context);
        if (successHandler) {
          result = await successHandler(context, result);
        }
        break;
      } catch (error) {
        retries += 1;
        if (retries === maxRetries) {
          if (!failHandler) {
            fn.state = "fulfilled";
            throw error;
          }
          const failResult = await failHandler(context, error);
          if (failResult !== undefined) {
            result = failResult;
            break;
          }
        }
        if (delay) {
          await new Promise((resolve) => setTimeout(() => resolve(), delay));
        }
      }
    }
    fn.state = "fulfilled";
    return result;
  };
  Object.assign(fn, { state: "initialized" });
  return fn;
};

export {
  readCookie,
  getLocalStorage,
  setToken,
  setRefreshToken,
  removeToken,
  isNameMatch,
  getDateFromPlanning,
  fieldNameByObject,
  getErrors,
  thisIsProd,
  isObjectEmpty,
  removeObjectKeys,
  debounce,
  throttle,
  numberToTime,
  daysAYear,
  clamp,
  isNumber,
  convertMinutesToHours,
  convertHoursToMinutes,
  pipe,
  retrier,
  calcElemCoords,
  group,
};
