const modalStore = {
  namespaced: true,
  state: {
    changeFieldModalData: null,
    confirmModalData: null,
  },
  getters: {
    getChangeFieldModalData(state) {
      return state.changeFieldModalData;
    },
  },
  mutations: {
    setChangeFieldModalData(state, value) {
      state.changeFieldModalData = value;
    },
    setConfirmModalData(state, data) {
      state.confirmModalData = data;
    },
    cleanConfirmModalData(state) {
        state.confirmModalData = null;
      },
  },
  actions: {},
};

export default modalStore;
