const dictionary = {
    "VerificationCode matching query does not exist.": "Не было запроса на код восстановления пароля или он устарел.",
    "Validation error. Invalid input.": "Ошибка ввода.",
    "Verification code does not match": "Указан неправильный код восстановления.",
    "This password is too short. It must contain at least 8 characters.": "Указанный пароль слишком короткий. Минимальная длина 8 символов.",
    "This password is too common.": "Ваш пароль слишком распространённый. Придумайте что-нибудь похитрее.",
    "No active account found with the given credentials": "Неправильный пользователь/пароль или пользователь неактивен.",
    "This password is entirely numeric.": "Пароль не может быть полностью числовым",
}

export default new Proxy(dictionary, {
    get(target, phrase) {
        if (phrase in target) {
            return target[phrase];
        } else {
            return phrase;
        }
    }
});
