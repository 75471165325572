import { MONTHS, WEEK_DAYS_SHORT } from "@/assets/constants.js";
import { DAY_TYPES } from "@/assets/enums.js";
import { group, calcElemCoords } from "@/assets/utils.js";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);
// ********
// STRUCT SetEx
class SetEx extends Set {
  constructor(initList, settings) {
    super(initList);
    const { deleteCondition = null } = settings || {};
    this.deleteCondition = deleteCondition;
  }

  addRange(list) {
    for (const elem of list) {
      this.add(elem);
    }
    return this;
  }

  union(list) {
    const _result = new SetEx(this);
    for (const elem of list) {
      _result.add(elem);
    }
    return _result;
  }

  intersection(list) {
    const _result = new SetEx();
    for (const elem of list) {
      if (this.has(elem)) {
        _result.add(elem);
      }
    }
    return _result;
  }

  difference(list) {
    const _result = new SetEx(this);
    for (const elem of list) {
      _result.delete(elem);
    }
    return _result;
  }

  symmetricDifference(list) {
    const listSet = new SetEx(list);
    const _result = new SetEx();
    for (const elem of this) {
      if (!listSet.has(elem)) {
        _result.add(elem);
      }
    }
    for (const elem of listSet) {
      if (!this.has(elem)) {
        _result.add(elem);
      }
    }
    return _result;
  }

  isSuperset(subset) {
    for (const elem of subset) {
      if (!this.has(elem)) {
        return false;
      }
    }
    return true;
  }

  isSubset(superset) {
    const _superset = new SetEx(superset);
    for (const elem of this) {
      if (!_superset.has(elem)) {
        return false;
      }
    }
    return true;
  }

  toString(separator = ", ") {
    let _string = "";
    this.forEach((elem, index) => {
      const _separator = index !== this.size ? separator : "";
      _string += elem + _separator;
    });
    return _string;
  }

  toArray() {
    return [...this];
  }
}

// ********
// STRUCT Range
// Создает итерируемый объект, возвращающий числовые значения от from до to
// Итерирование возможно при поможи for of или метода объекта forEachs
class Range {
  constructor({ from, to }) {
    this.from = from;
    this.to = to;
  }

  [Symbol.iterator]() {
    return {
      current: this.from,
      last: this.to,

      next() {
        if (this.current <= this.last) {
          return { done: false, value: this.current++ };
        } else {
          return { done: true };
        }
      },
    };
  }

  forEach(callback) {
    let index = 0;
    for (const item of this) {
      callback(item, index, this);
      index++;
    }
  }
}

// Cell-model for a TableCommon
class Cell {
  constructor({
    id,
    rows,
    value,
    columnSizes = [],
    classes = {},
    styles = {},
  }) {
    if (id === undefined) {
      throw new Error("id является обязательным параметром.");
    }
    if (!rows && value === undefined) {
      throw new Error(`[${id}] Необходимо передать 'value' или 'rows'.`);
    }
    this.id = id;
    this.classes = classes;
    this.styles = styles;
    this.columnSizes = columnSizes;
    if (rows) {
      if (!Array.isArray(rows)) {
        throw new TypeError(
          `Поле 'rows' должно быть типа 'array', а имеет тип ${typeof rows}`
        );
      }
      this.rows = rows;
    }
    if (value !== undefined) {
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "bigint" ||
        typeof value === "boolean" ||
        value === null
      ) {
        this.value = value;
      } else {
        throw new TypeError(
          `Для 'value' допустимы следующие типы: 'string', 'number', 'bigint', 'boolean', null.`
        );
      }
    }
  }

  addRow(row) {
    if (!this.rows) {
      throw new Error(
        "Экземпляр не имеет поля 'rows' и работает с полем 'value'"
      );
    }
    if (!(row instanceof Row)) {
      throw new TypeError(
        `Необходимо добавлять экземпляр класса Row. Убедитесь что для создания объекта вы используете класс Row`
      );
    }
    this.rows.push(row);
  }
}

// Row-model for a TableCommon
class Row {
  constructor({ id, cells, columnSizes = [], classes = {}, styles = {} }) {
    if (id === undefined) {
      throw new Error("id является обязательным параметром.");
    }
    if (!cells) {
      throw new Error(`[${id}] Поле 'cells' обязательное.`);
    } else if (!Array.isArray(cells)) {
      throw new TypeError(
        `Поле 'cells' должно быть типа 'array', а имеет тип ${typeof rows}`
      );
    }
    this.id = id;
    this.classes = classes;
    this.styles = styles;
    this.columnSizes = columnSizes;
    this.cells = cells;
  }

  addCell(cell) {
    if (cell instanceof Cell) {
      this.cells.push(cell);
    } else {
      throw new TypeError(
        `Необходимо добавлять экземпляр класса Cell. Убедитесь что для создания объекта вы используете класс Cell`
      );
    }
  }
}

class DayPC {
  constructor(day, dayOfWeek = null) {
    this.id = day.id;
    this.number = day.number;
    this.day_type = day.day_type;
    this.dayOfWeek = dayOfWeek;
  }
}

class MonthVacationTable {
  constructor(year) {
    this.id = null; // TODO: относится ко всем полям в этом констркуторе. Нужно понимать, какой тип даннах будет в свойствах. Если number, то дефолтное значение ставим = 0
    this.number = null;
    this.name = null;
    this.yearNumber = parseInt(year);
    this.days = [];
  }

  static create(year) {
    return new MonthVacationTable(year);
  }

  addDay(day) {
    if (this.id === null) {
      // TODO: id будет с типом данных number. Проверку просто делаешь как if (!this.id)
      this.id = day.month.id;
    }
    if (this.number === null) {
      // TODO: тоже самое. if (!this.number)
      this.number = day.month.name;
      this.name = MONTHS[day.month.name - 1];
    }
    const dayOfWeek = this.getDayOfWeek(this.number, day.number);

    this.days.push(new DayPC(day, dayOfWeek));
  }

  getDayOfWeek(monthNumber, dayNumber) {
    const dayOfWeekNumber =
      dayjs(`${this.yearNumber}-${monthNumber}-${dayNumber}`).isoWeekday() - 1;
    return WEEK_DAYS_SHORT[dayOfWeekNumber];
  }
}

class YearVacationTable {
  constructor(year) {
    this._year = parseInt(year);
    this._employeesDayTypes = new Map();
    this._employeesMonthsAvailability = new Map();
    this._calendar = Array.from({ length: 12 }, () =>
      // TODO: вот это число нужно вынести в константу и использовать ниже по коду
      MonthVacationTable.create(year)
    );
    this._editableMonthsState = new Map();
    this._edgesForDisplayedMonths = {
      start: 0, //TODO: на бекенде они с 1 начинают считать и нам так удобнее будет. Давай поставим с 1 до 12
      end: 12, // TODO: здесь по идее до 11 должно быть?
    };
  }

  static create(year) {
    return new YearVacationTable(year);
  }

  get productionCalendar() {
    const { start, end } = this._edgesForDisplayedMonths;
    return this._calendar.slice(start, end);
  }

  get displayedMonthsCount() {
    return this.productionCalendar.length;
  }

  getTypesOfModifiedDaysInMonth(employeeId, monthNumber) {
    return this._employeesDayTypes.get(employeeId)?.[monthNumber - 1];
  }

  initCalendar(calendar) {
    this._calendar = calendar.reduce((year, day) => {
      year[day.month.name - 1].addDay(day);
      return year;
    }, this._calendar);
  }

  initEmployee(employeeId, days) {
    if (!this._employeesDayTypes.has(employeeId)) {
      this._employeesDayTypes.set(
        employeeId,
        Array.from({ length: 12 }, () => new Map())
      );
      this._editableMonthsState.set(employeeId, new Array(12).fill(true));
      days.forEach((day) => {
        this.modifyDayType(
          employeeId,
          day.pc_day.month.name,
          day.pc_day.number,
          day.day_type
        );
      });
      return true;
    }
  }

  setMonthsAvailabilityForEmployee(employeeId, isEditable) {
    for (const [monthIndex, month] of this._calendar.entries()) {
      this._editableMonthsState.get(employeeId)[monthIndex] = isEditable(
        employeeId,
        month,
        monthIndex
      );
    }
  }

  getMonthNameByIndex(monthIndex) {
    return this._calendar[monthIndex].name || "";
  }

  getMonthIdByIndex(monthIndex) {
    return this._calendar[monthIndex].id;
  }

  getDayId(monthIndex, dayIndex) {
    return this._calendar[monthIndex].days[dayIndex].id;
  }

  isMonthEditable(employeeId, monthIndex) {
    const { start, end } = this._edgesForDisplayedMonths;
    return this._editableMonthsState.get(employeeId)?.slice(start, end)[
      monthIndex
    ];
  }

  getDayType(monthNumber, dayNumber, employeeId = null) {
    const modifiedDaysInMonth = this.getTypesOfModifiedDaysInMonth(
      employeeId,
      monthNumber
    );
    if (employeeId !== null && modifiedDaysInMonth?.has(dayNumber)) {
      return modifiedDaysInMonth.get(dayNumber);
    } else {
      return this._calendar[monthNumber - 1].days[dayNumber - 1].day_type;
    }
  }

  modifyDayType(employeeId, monthNumber, dayNumber, type) {
    const modifiedDaysInMonth = this.getTypesOfModifiedDaysInMonth(
      employeeId,
      monthNumber
    );
    if (type === DAY_TYPES.WORKING) {
      modifiedDaysInMonth.delete(dayNumber);
    } else {
      modifiedDaysInMonth.set(dayNumber, type);
    }
    return true;
  }

  modifyIntervalType(interval, type, dateTransformer) {
    interval.forEach((date) => {
      const [employeeId, monthIndex, dayIndex] = dateTransformer(date[1]); // TODO: если решишь от индексов перейти к номерам, то тут тоже не забудь попроавить
      this.modifyDayType(employeeId, monthIndex + 1, dayIndex + 1, type);
    });
  }

  setDisplayedMonths(first, second) {
    this._edgesForDisplayedMonths = { start: first, end: second };
    return true;
  }
}

class Hierarchy {
  constructor(settings) {
    this.root = null;
    this.settings = settings;
  }

  static getStyleValueByElement(element, properties, valueHandler) {
    if (!window || !element) {
      return null;
    }
    const styles = window.getComputedStyle(element, null);

    return properties.map((property) => {
      let propertyValue = styles.getPropertyValue(property) || null;
      if (valueHandler) {
        propertyValue = valueHandler(propertyValue);
      }
      return propertyValue;
    });
  }

  init(elements) {
    const nodes = Array.from(elements).map(
      (elem, index) => new HierarchyNode(elem, index, this.settings)
    );
    const root = this.getRoot(nodes);
    const groups = group(nodes, (node) => this.getParent(node._temp.element));
    this.root = root;
    this.setChildren(root, groups, this.settings);
    nodes.forEach((node) => node.removeTemp());
    return this;
  }

  getParent(element) {
    return element.parentNode.parentNode.parentNode;
  }

  isParentChild(potencialParent, potencialChild) {
    return (
      potencialParent._temp.element.parentNode ===
      this.getParent(potencialChild._temp.element)
    );
  }

  getRoot(nodes) {
    let root = null;

    for (const node of nodes) {
      let isRoot = true;
      deepLoop: for (const nodeDeepLoop of nodes) {
        if (
          !node._temp.element.parentNode.contains(nodeDeepLoop._temp.element)
        ) {
          isRoot = false;
          break deepLoop;
        }
      }
      if (isRoot) {
        root = node;
        break;
      }
    }
    root?.setCoords();
    return root;
  }

  setChildren(parent, groups) {
    for (const group of groups) {
      if (this.isParentChild(parent, group.at(0))) {
        for (const [index, node] of group.entries()) {
          parent.addChild(node);
          node.setParent(parent);
          node.setCoords();

          if (index === 0) {
            node.setRelation(group.at(-1), "start");
          } else if (index === group.length - 1) {
            node.setRelation(group.at(0), "end");
          } else {
            node.setRelation(null, "intermediate");
          }

          this.setChildren(node, groups);
        }
      }
    }
  }

  getEach() {
    const result = [];

    const traverse = (item) => {
      result.push(item);
      if (item.children && item.children.length > 0) {
        for (const child of item.children) {
          traverse(child);
        }
      }
    };

    traverse(this.root);

    return result;
  }

  each(callback) {
    const nodes = this.getEach();
    for (const [index, node] of nodes.entries()) {
      callback(node, index, nodes);
    }
  }

  drawLinks(container) {
    const root =
      container instanceof Element
        ? container
        : document.querySelector(container);

    const [width, height] = Hierarchy.getStyleValueByElement(
      root,
      ["width", "height"],
      parseInt
    );
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.classList.add("svg-background");
    svg.setAttribute("x", "0");
    svg.setAttribute("y", "0");
    svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
    svg.setAttribute("width", width);
    svg.setAttribute("height", height);

    this.each((node) => {
      const lines = node.drawLines();
      lines.forEach((line) => svg.append(line));
    });
    root.append(svg);
    return this;
  }
}

class HierarchyNode {
  constructor(element, id, settings) {
    this.id = id;
    this.parent = null;
    this.children = [];
    this.relation = null;
    this.nodePosition = null;
    this.coords = { top: { x: null, y: null }, bottom: { x: null, y: null } };
    this._styles = null;
    if (window) {
      this._styles = window.getComputedStyle(element, null);
    }
    Object.defineProperty(this, "_temp", {
      value: {
        element,
        settings,
      },
      enumerable: false,
      configurable: true,
    });
  }

  setParent(parent) {
    this.parent = parent;
  }

  addChild(child) {
    this.children.push(child);
  }

  setRelation(node, type) {
    this.relation = node;
    this.nodePosition = type;
  }

  getStyleValue(property, valueHandler) {
    const value = this._styles?.getPropertyValue(property) || null;
    return valueHandler && value !== null ? valueHandler(value) : value;
  }

  calcStyleValues(properties, valuesHandler, valuePreparer) {
    const values = properties.reduce((result, property) => {
      const value = this.getStyleValue(property, valuePreparer);
      result.push(value);
      return result;
    }, []);
    return valuesHandler(values);
  }

  getCoords() {
    if (!window) {
      return {};
    }

    const pointsDefiner = (rect) => {
      const coords = {
        top: { x: null, y: null },
        bottom: { x: null, y: null },
      };
      const { correction = 0 } = this._temp.settings || {};

      const borderWidth = this.getStyleValue("border-width", parseInt);

      for (const position of ["top", "bottom"]) {
        if (!this.parent && position === "top") {
          continue;
        }
        coords[position].x = rect.width / 2 + (rect.left - correction);
        if (position === "top") {
          coords.top.y =
            rect.top + window.scrollY - borderWidth - rect.height / 2;
        } else {
          coords.bottom.y = rect.height + coords.top.y;
        }
      }
      return coords;
    };
    return calcElemCoords(this._temp.element, pointsDefiner);
  }

  setCoords() {
    this.coords = this.getCoords();
  }

  drawLines() {
    const lines = [];
    const LINE_WIDTH = 3;
    const createLine = ({ x1, y1, x2, y2 }) => {
      const line = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "line"
      );
      line.setAttribute("stroke", "#263238");
      line.setAttribute("x1", x1);
      line.setAttribute("y1", y1);
      line.setAttribute("x2", x2);
      line.setAttribute("y2", y2);
      line.setAttribute("stroke-width", LINE_WIDTH);
      return line;
    };

    const getHalfSize = (value) => parseInt(value) / 2;

    if (this.children.length) {
      const lineHeight = this.getStyleValue("margin-bottom", getHalfSize);
      const { x: x1, y: y1 } = this.coords.bottom;
      const coords = {
        x1: x1,
        y1: y1,
        x2: x1,
        y2: y1 + lineHeight,
      };
      const bottom = createLine(coords);
      lines.push(bottom);
    }

    if (this.parent) {
      const lineHeight = this.parent.getStyleValue(
        "margin-bottom",
        getHalfSize
      );
      const { x: x1, y: y1 } = this.coords.top;
      const coords = {
        x1,
        y1,
        x2: x1,
        y2: y1 - lineHeight,
      };
      const top = createLine(coords);
      lines.push(top);
    }

    if (this.relation && this.nodePosition === "start") {
      const parentHalfMargin = this.parent.getStyleValue(
        "margin-bottom",
        getHalfSize
      );
      const { x: x1, y: y1 } = this.coords.top;
      const { x: x2, y: y2 } = this.relation.coords.top;
      const coords = {
        x1: x1 - LINE_WIDTH / 2,
        x2: x2 + LINE_WIDTH / 2,
        y1: y1 - parentHalfMargin,
        y2: y2 - parentHalfMargin,
      };
      const horizontal = createLine(coords);
      lines.push(horizontal);
    }
    return lines;
  }

  removeTemp() {
    delete this._temp;
  }
}

export { SetEx, Range, Cell, Row, YearVacationTable, Hierarchy };
