import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Staff {
  constructor() {
    Binder.bind(this, Staff);
    this.apiService = new ApiService("/api/staff");
  }

  list(params) {
    return this.apiService.get("", { ...params });
  }

  one(id) {
    return this.apiService.get(id);
  }

  getWorkingYears(id) {
    return this.apiService.get(`${id}/working-years`);
  }

  getWorkingMonths(id) {
    return this.apiService.get(`${id}/working-months`);
  }

  create(data) {
    return this.apiService.post("", {}, data);
  }

  update(id, data) {
    return this.apiService.patch(id, {}, data);
  }
}

export default new Staff();
