import api from "../api";
import router from "@/router";
import { setToken, setRefreshToken, removeToken } from "@/assets/utils";

const authStore = {
  namespaced: true,
  state: {
    isAuth: false,
    me: {},
  },
  getters: {},
  mutations: {
    setAuth(state, value) {
      state.isAuth = value;
    },
  },
  actions: {
    async getToken({ commit }, params = {}) {
      const { email, password } = params;
      const response = await api.auth.getToken({ email, password });
      if (!response.access) {
        throw new Error();
      }
      setToken(response.access);
      setRefreshToken(response.refresh);
      commit("setAuth", true);
    },
    noAuth: {
      root: true,
      async handler({ commit }, error) {
        if (error.code === 401) {
          commit("setAuth", false);
          removeToken();
          router.push({ name: "auth" });
        }
      },
    },
    async resetPassword(context, params = {}) {
      const { email } = params;
      await api.auth.resetPassword(email);
    },
    async setPassword(content, params = {}) {
      const { email, code, password } = params;
      await api.auth.setPassword({ email, code, password });
    },
  },
};

export default authStore;
