import auth from "./entities/auth";
import companyStructure from "./entities/companyStructure";
import employeeCalendar from "./entities/employeeCalendar";
import expenses from "./entities/expenses";
import productionCalendar from "./entities/productionCalendar";
import plannings from "./entities/plannings";
import projects from "./entities/projects";
import reports from "./entities/reports";
import staff from "./entities/staff";
import users from "./entities/users";

export default {
  auth,
  companyStructure,
  employeeCalendar,
  expenses,
  productionCalendar,
  plannings,
  projects,
  reports,
  staff,
  users,
};
