<template>
  <div class="erm-secondary">
    <main class="content">
      <router-view />
      <InfoModal
        v-for="message of messagesDataList"
        :key="message.id"
        :data="message"
      />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InfoModal from "@/components/modal/InfoModal";

export default {
  name: "secondary-layout",
  components: {
    InfoModal,
  },
  computed: {
    ...mapState({
      messagesSet: (state) => state.messagesStore.messagesSet,
      messagesUpdateNumber: (state) => state.messagesStore.messagesUpdateNumber,
    }),
    messagesDataList() {
      return this.messagesUpdateNumber && this.messagesSet.toArray();
    },
  },
};
</script>

<style lang="scss">
.erm-secondary {
  min-height: 100vh;
  width: 100vw;
}
</style>
