const settingsStore = {
  namespaced: true,
  state: {
    infoModalLifeTime: 12,
    modalMessageTypes: ["info"],
    consoleMessageTypes: ["warn", "error"],
  },
  mutations: {
    setInfoModalLifeTime(state, time) {
      state.infoModalLifeTime = time;
    },
    setModalMessageTypes(state, types) {
      state.modalMessageTypes = types;
    },
    setConsoleMessageTypes(state, types) {
      state.consoleMessageTypes = types;
    },
  },
  actions: {
    saveSettings({ commit }, settings) {
      const {
        info_modal_life_time,
        info_modal_message_types,
        info_console_message_types,
      } = settings;
      commit("setInfoModalLifeTime", info_modal_life_time);
      commit("setModalMessageTypes", info_modal_message_types);
      commit("setConsoleMessageTypes", info_console_message_types);
      localStorage.setItem("_erp_settings", JSON.stringify(settings));
    },
    restoreSettings({ commit }) {
      let settings = localStorage.getItem("_erp_settings");
      if (!settings) {
        return;
      }
      const {
        info_modal_life_time,
        info_modal_message_types,
        info_console_message_types,
      } = JSON.parse(settings);
      commit("setInfoModalLifeTime", info_modal_life_time);
      commit("setModalMessageTypes", info_modal_message_types);
      commit("setConsoleMessageTypes", info_console_message_types);
      
    },
  },
  getters: {},
};

export default settingsStore;
