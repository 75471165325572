const Binder = {
  getAllMethods: (instance, cls) => {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(instance)).filter(name => {
      const method = instance[name];
      return !(!(method instanceof Function) || method === cls);
    });
  },
  bind: (instance, cls) => {
    Binder.getAllMethods(instance, cls).forEach(mtd => {
      // eslint-disable-next-line no-param-reassign
      instance[mtd] = instance[mtd].bind(instance);
    });
  },
};

export default Binder;
