import { ROLES } from "@/assets/enums";
// при записи правил для нужного роута необходимо указать имя этого роута как в файле router/index.js
// Например: "main"
// Правила для каждого роута описываются через массив,
// первый элемент которого указывает тип правила [ "onlyFor" - для всех из списка | "eachExcept" - для всех кроме указанных в списке ] *
// второй элемент - список (массив) ролей, к которым применяется правило
//
// * - если у сотрудника есть роль, для которой доступ разрешен, то даже при наличии запрещенных ролей будет возвращено true

const rules = {
  planning: ["onlyFor", [ROLES.FOUNDER, ROLES.TM, ROLES.PMO, ROLES.PM]],
  "monthly-planning": ["onlyFor", [ROLES.FOUNDER, ROLES.PM]],
  bookkeeping: ["onlyFor", [ROLES.FOUNDER, ROLES.BOOKKEEPER]],
  "expenses-view": ["onlyFor", [ROLES.FOUNDER, ROLES.PM, ROLES.HR]],
};

export default function (route, roles) {
  if (
    !route ||
    !roles ||
    !rules[route] ||
    !rules[route][0] ||
    !rules[route][1] ||
    !rules[route][1].length
  ) {
    return true;
  }
  const rule = rules[route][0];
  const ruleRoles = rules[route][1];
  if (rule === "onlyFor") {
    return ruleRoles.some((ruleRole) => roles.includes(ruleRole));
  }
  if (rule === "eachExcept") {
    return !roles.every((rule) => ruleRoles.includes(rule));
  }
}
