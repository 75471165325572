import api from "@/api";

const companyStructureStore = {
  namespaced: true,
  state: {
    organizationsList: null,
  },
  getters: {},
  mutations: {
    setOrganizationsList(state, organizations) {
      state.organizationsList = organizations;
    },
  },
  actions: {
    async loadOrganizationsList({ commit }) {
      const { results } = await api.companyStructure.organizationsList();
      commit("setOrganizationsList", results[0]);
      return results;
    },
  },
};

export default companyStructureStore;
