import ApiService from "../apiService";
import Binder from "../../assets/binder";

class ProductionCalendar {
  constructor() {
    Binder.bind(this, ProductionCalendar);
    this.apiService = new ApiService("/api/production-calendar");
  }

  getMonths({ year_id, limit, offset }) {
    return this.apiService.get("months", { year_id, limit, offset });
  }

  getYears({ limit = 10 }) {
    return this.apiService.get("years", { limit });
  }

  getTimeZones() {
    return this.apiService.get("timezones");
  }

  getOneMonth({ id }) {
    return this.apiService.get(`months/${id}`);
  }

  getDays({ year_id, month_id, limit = 31, offset }) {
    return this.apiService.get("days", { year_id, month_id, limit, offset });
  }

  getServerTime() {
    return this.apiService.get("now");
  }
}

export default new ProductionCalendar();
