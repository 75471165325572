import { SetEx } from "@/assets/dataStructs";

const messagesStore = {
  namespaced: true,
  state: {
    messagesUpdateNumber: 0,
    messageId: 1,
    messagesSet: new SetEx(),
    messagesValueSet: new SetEx(),
  },
  getters: {},
  mutations: {},
  actions: {
    setMessage({ state, rootState }, value) {
      if (state.messagesValueSet.has(value.message) && !value.withoutDelay) {
        return;
      }
      if (!("type" in value)) {
        value.type = "info";
      }
      value.id = state.messageId;
      state.messagesValueSet.add(value.message); // добавляем сообщение чтобы не выводить повторно в модалке
      if (rootState.settingsStore.modalMessageTypes.includes(value.type)) {
        state.messagesSet.add(value);
      }
      state.messagesUpdateNumber++; // хак для динамичекой отрисовки типа Set
      if (rootState.settingsStore.consoleMessageTypes.includes(value.type)) {
        let consoleText = `[INFO] ID сообщения: ${state.messageId}.\n`;
        if (value.message) {
          const message =
            typeof value.message === "object"
              ? JSON.stringify(value.message)
              : value.message;
          consoleText += `Текст сообщения: ${message}.\n`;
        }
        if (value.location) {
          consoleText += `Место возникновения: ${value.location}.`;
        }
        if (value.type === "error") {
          console.error(consoleText);
        } else {
          console.log(consoleText);
        }
        if (value.app) {
          console.warn("app", value.app);
        }
      }
      setTimeout(() => {
        state.messagesSet.delete(value); // удаляем сообщение через установленное время
        state.messagesValueSet.delete(value.message);
        state.messagesUpdateNumber++;
      }, value.lifeTime * 1000 || rootState.settingsStore.infoModalLifeTime * 1000);

      state.messageId++; // увеличиваем id (нужен для соотнесения информацией в модалке с информацией в консоли)
    },
    removeMessage({ state }, message) {
      state.messagesValueSet.delete(message);
    },
  },
};

export default messagesStore;
