import _ from "lodash";
import interceptor from "../assets/interceptor";
import { readCookie, getLocalStorage } from "../assets/utils";
import { JWT_KEY } from "../assets/constants";

const REQUEST_CACHE = {};

export const LIST_LIMIT = 10;

class ApiService {
  constructor(urlPrefix, requestPrepare, host) {
    this.requestPrepare = requestPrepare;
    this.interceptor = interceptor;
    this.urlPrefix = urlPrefix;
    this.host = host || window.location.origin || "https://dev.erp.satva.dev/";
    this.isRepeated = false;
  }

  async request(
    method,
    url,
    params = {},
    body = {},
    headers = {},
    contentType = true,
    host = "",
    cache = null,
    checkCache = false,
    isBlobType = false
  ) {
    const jwt = getLocalStorage(JWT_KEY);

    const urlPrefix = this.urlPrefix;
    if (jwt) {
      headers["Authorization"] = `Bearer ${jwt}`;
    }
    if (!process.client) {
      cache = false;
    }
    if (cache === null) {
      cache = method === "GET";
    }
    if (method !== "GET") {
      headers["X-CSRFToken"] = readCookie("csrftoken");
    }
    if (process.server && global.cookie) {
      headers = {
        ...headers,
        cookie: global.cookie,
        Authorization: "Basic " + process.env.BASIC_AUTH,
      };
    }

    if (this.requestPrepare) {
      const newParams = await this.requestPrepare(
        method,
        url,
        params,
        body,
        headers
      );
      method = newParams.method;
      url = newParams.url;
      params = newParams.params;
      body = newParams.body;
      headers = newParams.headers || {};
    }

    const fullUrl = new URL(
      `${urlPrefix}/${url ? url + "/" : ""}`,
      host || this.host
    );
    // Формируем параметры fetch
    const requestParams = {
      method,
      headers: {
        ...headers,
      },
      credentials: "include",
    };
    // Для возможности отключить поле content-type. Pixel не обрабатывает запросы с content-type.
    if (contentType) {
      requestParams.headers["content-type"] = "application/json";
    }
    // Для методов, предполагающих тело запроса добавляем тело запроса
    if (body && ["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
      requestParams.body = JSON.stringify(body);
    }
    if (params) {
      fullUrl.search = new URLSearchParams(_.pickBy(params, _.identity));
    }

    if (cache && REQUEST_CACHE[fullUrl.toString()]) {
      return _.cloneDeep(REQUEST_CACHE[fullUrl.toString()]);
    }
    if (checkCache) {
      return false;
    }

    let response;
    try {
      response = await fetch(fullUrl, requestParams);
    } catch (error) {
      response = {
        statusText: error.message,
      };
    }

    let result = null;
    if (response.ok) {
      if (isBlobType) {
        result = await response.blob();
      } else {
        // обрабатываем случай пустого body ответа
        result = await response.text();
        result = result ? JSON.parse(result) : {};
      }
    } else {
      try {
        result = await response.json();
        result.code = response.status;
      } catch (e) {
        result = {
          error_code: response.status,
          message: response.statusText,
          code: response.status,
        };
      }
    }

    // перехватываем 401 код ответа
    if (this.interceptor) {
      result = await this.interceptor(
        { status: response.status, result, response, isBlobType },
        method,
        url,
        params,
        body,
        headers
      );
    }

    if (cache) {
      REQUEST_CACHE[fullUrl.toString()] = _.cloneDeep(result);
    }
    return result;
  }

  get(
    url,
    params = {},
    headers = {},
    contentType = true,
    host = "",
    cache = true,
    isBlobType = false
  ) {
    return this.request(
      "GET",
      url,
      params,
      {},
      headers,
      contentType,
      host,
      cache,
      false,
      isBlobType
    );
  }

  checkCache(
    url,
    params = {},
    headers = {},
    contentType = true,
    host = "",
    isBlobType = false
  ) {
    return this.request(
      "GET",
      url,
      params,
      {},
      headers,
      contentType,
      host,
      true,
      true,
      isBlobType
    );
  }

  post(url, params, body, headers = {}) {
    return this.request("POST", url, params, body, headers);
  }

  patch(url, params, body, headers = {}) {
    return this.request("PATCH", url, params, body, headers);
  }

  put(url, params, body, headers = {}) {
    return this.request("PUT", url, params, body, headers);
  }

  delete(url, params, body, headers = {}) {
    return this.request("DELETE", url, params, body, headers);
  }
}

export default ApiService;
