import api from "../api";
import { removeObjectKeys } from "@/assets/utils";
import { MONTHS_LIST_LIMIT } from "@/assets/constants";
import { debounce, getDateFromPlanning } from "@/assets/utils";

const storageKey = "_erp_planning_filters";

const planningsStore = {
  namespaced: true,
  state: {
    monthId: null,
    pageNumber: 1,
    datesData: null,
    lastUpdate: Date.now(),
    planningsList: [],
    planningProjectsList: [],
    staffActivities: [],
    activitiesList: [],
    summaryActivities: [],
    error: "",
    positionFilter: [],
    activeProjectsTypes: {
      production: ["true"],
      commercial: ["true"],
    },
    availableEmployeeMonthsPlanningsList: [],
    isChangeEmployeeMonthsPlanningsListModeAdd: true, // true - "add", false - "delete",
    currentEmployeeMonthsPlanningsListId: 0,
    currentMonthForAvailableMonthsPlanningsList: 0,
  },
  getters: {
    currentMonthID: (state, getters, rootState, rootGetters) => {
      const currentDate = rootGetters["userStore/getMyDate"];
      if (!currentDate) {
        return null;
      }
      const [currentYear, currentMonth] = currentDate
        .format("YYYY-MM")
        .split("-");
      const month = state.planningsList.find(
        (month) =>
          month.name === parseInt(currentMonth) &&
          month.year.number === parseInt(currentYear)
      );
      return month?.id || null;
    },
    planningsListAs:
      (state) =>
      (format = "string") => {
        return state.planningsList.map((date) => {
          return getDateFromPlanning(date, format);
        });
      },
    getMonthID:
      (state) =>
      ({ year, month }) => {
        return (
          (
            state.planningsList.find(
              (date) => date.name === month && date.year.number === year
            ) || {}
          ).id || null
        );
      },
    getActivitiesForSelect: (state) => {
      return state.activitiesList.map((activity) => ({
        id: activity.id,
        name: activity.role,
      }));
    },
    getAvailableEmployeeMonthsPlanningsList: (state) => {
      return state.availableEmployeeMonthsPlanningsList;
    },
    getIsChangeEmployeeMonthsPlanningsListModeAdd: (state) => {
      return state.isChangeEmployeeMonthsPlanningsListModeAdd;
    },
    getCurrentEmployeeMonthsPlanningsListId: (state) => {
      return state.currentEmployeeMonthsPlanningsListId;
    },
    getCurrentMonthForAvailableMonthsPlanningsList: (state) => {
      return state.currentMonthForAvailableMonthsPlanningsList;
    },
    getLastUpdate: (state) => {
      return state.lastUpdate;
    },
  },
  mutations: {
    setMonthId(state, id) {
      state.monthId = id;
    },
    setDatesData(state, value) {
      state.datesData = value;
    },
    setPageNumber(state, value) {
      state.pageNumber = value;
    },
    setLastUpdate(state) {
      state.lastUpdate = Date.now();
    },
    setPlanningsList(state, value) {
      state.planningsList = value;
    },
    setPlanningProjectsList(state, value) {
      state.planningProjectsList = value;
    },
    setStaffActivities(state, value) {
      state.staffActivities = value;
    },
    setSummaryActivities(state, value) {
      state.summaryActivities = value;
    },
    clearStaffActivities(state) {
      state.staffActivities = [];
    },
    setActivitiesList(state, value) {
      state.activitiesList = value;
    },
    setPlanningsError(state, value) {
      state.error = value;
    },
    setPositionFilter(state, positionsList) {
      state.positionFilter = positionsList;
    },
    clearPositionFilter(state) {
      state.positionFilter = [];
    },
    setExactProjectsTypes(state, { selectedProjectsTypes, typeName }) {
      state.activeProjectsTypes[typeName] = selectedProjectsTypes;
    },
    setProjectsTypes(state, types) {
      state.activeProjectsTypes = types;
    },
    setAvailableEmployeeMonthsPlanningsList(state, value) {
      state.availableEmployeeMonthsPlanningsList = value;
    },
    setCurrentMonthForAvailableMonthsPlanningsList(state, value) {
      state.currentMonthForAvailableMonthsPlanningsList = value;
    },
    setIsChangeEmployeeMonthsPlanningsListModeAdd(state, value) {
      state.isChangeEmployeeMonthsPlanningsListModeAdd = value;
    },
    setCurrentEmployeeMonthsPlanningsListId(state, value) {
      state.currentEmployeeMonthsPlanningsListId = value;
    },
  },
  actions: {
    async setPageNumber({ commit, dispatch }, pageNumber) {
      commit("setPageNumber", pageNumber);
      await dispatch("loadPlanningsList");
    },
    async loadPlanningsList({ state, commit, dispatch }, params = {}) {
      try {
        const limit = MONTHS_LIST_LIMIT;
        const response = await api.plannings.list({
          offset: (state.pageNumber - 1) * limit,
          limit,
          ...params,
        });
        commit("setPlanningsList", response.results);
        commit("setDatesData", removeObjectKeys(response, "results"));
      } catch (e) {
        commit("setPlanningsError", "нет доступа к таблице");
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка планирований`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadActivitiesList({ commit, dispatch, state }) {
      if (state.activitiesList.length) {
        return;
      }
      try {
        const response = await api.plannings.activitiesList();
        commit("setActivitiesList", response.results);
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка доступных направлений деятельности сотрудников`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async createPlanning({ dispatch }) {
      try {
        await api.plannings.createPlanning();
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Создание планирования`,
            message: `Ошибка сети. Не удалось создать новое планирование.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadPlanningProjectsList({ commit, dispatch, state }) {
      const { activeProjectsTypes, monthId } = state;
      const { production, commercial } = activeProjectsTypes;
      try {
        const isProduction = production.length === 1 ? production[0] : null;
        const isCommercial = commercial.length === 1 ? commercial[0] : null;

        const response = await api.plannings.getProjectsByMonth(
          monthId,
          isProduction,
          isCommercial
        );
        commit("setPlanningProjectsList", response.results);
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка данных по проектам`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadStaffActivities({ commit, dispatch, state }) {
      try {
        const params = {
          id__in: state.positionFilter.filter((position) => position).join(","),
        };
        const response = await api.plannings.getStaffActivityByMonth(
          state.monthId,
          params
        );
        commit("setStaffActivities", response.results);
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        console.error(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение сводных данных по нагрузке сотрудников`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
        throw e;
      }
    },
    async loadSummaryStaffActivities({ commit, dispatch }, params) {
      try {
        const response = await api.plannings.summaryActivity(params);
        commit("setSummaryActivities", response.results);
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        console.error(e);
      }
    },
    async changeStaffActivity({ dispatch }, params) {
      try {
        await api.plannings.changeStaffActivity(params);
        return { success: true };
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Изменение доступности и комментария для месяца сотрудника`,
            message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
          },
          { root: true }
        );
        return { success: false };
      }
    },
    async changeStaffDayActivity({ dispatch }, params) {
      try {
        await api.plannings.changeStaffDayActivity(params);
        return { success: true };
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Изменение доступности за день в месяце`,
            message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
          },
          { root: true }
        );
        return { success: false };
      }
    },
    async deleteMonthlyPlanning({ dispatch }, id) {
      try {
        await api.plannings.deleteMonthlyPlanning(id);
        await dispatch("loadPlanningsList");
      } catch (e) {
        console.warn("deleteMonthlyPlanning:", e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Удаление планирования`,
            message: `Ошибка сети. Не удалось удалить планирование.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    setPositionFilter: debounce(async function (
      { commit, dispatch },
      positions
    ) {
      commit("setPositionFilter", positions);
      await dispatch("loadStaffActivities");
    },
    700),
    setProjectsTypes: debounce(async function (
      { commit, dispatch },
      selectedProjectsTypes
    ) {
      await commit("setExactProjectsTypes", selectedProjectsTypes);
      await dispatch("saveFilters");
      await dispatch("loadPlanningProjectsList");
    },
    700),
    saveFilters({ state }) {
      const filters = {
        projects_types: state.activeProjectsTypes,
      };
      localStorage.setItem(storageKey, JSON.stringify(filters));
    },
    async restoreFilters({ commit }) {
      let filters = localStorage.getItem(storageKey);
      if (!filters) {
        return {};
      }
      const { projects_types } = JSON.parse(filters);
      commit("setProjectsTypes", projects_types);
    },

    async checkAvailableEmployeeMonthsPlanningsList(
      { dispatch, commit },
      { sub_assignment_id, isDeletion, currentMonthId }
    ) {
      try {
        const result = await api.plannings.checkAvailableEmployeePlanningsList(
          sub_assignment_id,
          isDeletion
        );

        if (!result.count) {
          dispatch(
            "messagesStore/setMessage",
            {
              title: "Удаление сотрудника",
              message: "Действие запрещено/редактирование запрещено",
            },
            { root: true }
          );
        }

        const formattedMonths = result.results.filter((availableMonth) => {
          return availableMonth.id >= currentMonthId;
        });

        commit(
          "setCurrentMonthForAvailableMonthsPlanningsList",
          currentMonthId
        );

        return await commit(
          "setAvailableEmployeeMonthsPlanningsList",
          formattedMonths
        );
      } catch (e) {
        console.warn("checkAvailableEmployeeMonthsPlanningsList:", e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} ${
              isDeletion ? "Удаление" : "Изменение"
            } сотрудника`,
            message: `Ошибка сети. Не удалось ${
              isDeletion ? "удалить" : "изменить"
            } сотрудника.\n${e.message}`,
          },
          { root: true }
        );
      }
    },

    async changeAvailableEmployeeMonthsPlanningsList(
      _action,
      { sub_assignment_id, isDeletion, month_ids }
    ) {
      try {
        return await api.plannings.changeEmployeePlanningsList(
          sub_assignment_id,
          isDeletion,
          month_ids
        );
      } catch (e) {
        console.warn("changeAvailableEmployeeMonthsPlanningsList:", e);
        // dispatch(
        //   "messagesStore/setMessage",
        //   {
        //     title: `${e.code} ${
        //       isDeletion ? "Удаление" : "Изменение"
        //     } сотрудника`,
        //     message: `Ошибка сети. Не удалось ${
        //       isDeletion ? "удалить" : "изменить"
        //     } сотрудника.\n${e.message}`,
        //   },
        //   { root: true }
        // );

        return e.result;
      }
    },
  },
};

export default planningsStore;
