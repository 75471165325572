<template>
  <modal :data="modalDesign" class="modal--message" ref="modal">
    <p>{{ data.message }}</p>
  </modal>
</template>

<script>
import modal from "./ModalProto";

export default {
  name: "InfoModal",
  components: {
    modal,
  },
  props: {
    data: {
      type: Object,
    },
  },
  mounted() {
    this.$refs.modal.open(false);
  },
  computed: {
    modalDesign() {
      const DESIGNS = {
        error: {
          title: `Ошибка. ID ${this.data.id}`,
          classes: {
            header: "modal-message__header--error",
          },
        },
        warn: {
          title: `Предупреждение. ID ${this.data.id}`,
          classes: {
            header: "modal-message__header--warn",
          },
        },
        info: {
          title: this.data.title || `Сообщение. ID ${this.data.id}`,
        },
      };

      return {
        title: DESIGNS[this.data.type].title,
        classes: DESIGNS[this.data.type].classes,
        actions: [
          {
            id: "accept",
            title: `Ok`,
            classes: "modal-message__accept",
            handler: () => {
              const modal = this.$refs.modal;
              modal.close();
              this.$store.dispatch(
                "messagesStore/removeMessage",
                this.data.message
              );
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";
</style>
