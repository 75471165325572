<template>
  <button
    type="button"
    class="button"
    :class="{
      [`button--${BUTTON_THEMES[theme]}`]: theme, // common, icon, label, modal, auth, hoverIcon
      [`button--${BUTTON_COLORS[color]}`]: color, // accept, reset, warning
      'button--loading': isLoading,
      'button--disabled': disabled,
    }"
    :disabled="disabled"
    @click="!isLoading ? $emit('click') : null"
  >
    <slot v-if="!isLoading"></slot>
    <span v-else>Выполняется...</span>
  </button>
</template>

<script>
import { BUTTON_THEMES, BUTTON_COLORS } from "@/assets/enums";
export default {
  name: "Button",
  data() {
    return {
      BUTTON_THEMES,
      BUTTON_COLORS,
    };
  },
  props: {
    theme: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  color: inherit;
  cursor: pointer;
}
.button--loading {
  animation: change-background 4s linear infinite;
}
.button--common {
  padding: 6px 16px;
  border: 2px solid $erp-dark-color;
  color: $erp-dark-color;
  border-radius: 7px;
  cursor: pointer;
  min-width: 250px;
  transition: 300ms background-color;

  &:hover {
    background-color: $erp-hover-color;
  }

  &.button--disabled {
    border: 2px solid $erp-disabled-color;
  }
}
.button--icon,
.button--hoverIcon {
  border: none;
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding: 0;
  background-color: transparent;
  flex-shrink: 0;
  svg {
    width: 100%;
    height: 100%;
  }
}
.button--hoverIcon {
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
}
.button--label {
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-weight: 600;
  transition: 300ms;
  &:hover {
    transition: 300ms;
    color: #247096;
  }
}
.button--modal {
  width: 100%;
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid $erp-primary-bg-color;
  background-color: #fff;
  color: #2c2c2c;
}

.button--auth {
  min-width: 200px;
  width: min-content;
  background-color: #4676d7;
  transition: 300ms background-color;
  &:hover {
    background-color: #93b4f7;
  }
}

.button--disabled {
  pointer-events: none;
  cursor: default;
  color: $erp-disabled-color;
  &.button--hoverIcon {
    opacity: 0.3;
  }
  &.button--auth {
    cursor: default;
    background-color: rgba(128, 128, 128, 0.4);
    &:hover {
      background-color: rgba(128, 128, 128, 0.4);
    }
  }
}

.button--accept {
  background-color: #67e067;
  color: #ffffff;
  border: none;
  &:hover {
    transition: 300ms;
    color: #ffffff;
    background-color: #6bd66b;
  }
}

.button--reset {
  background-color: #518a97;
  &:hover {
    background-color: #86a2a9;
  }
}
.button--warning {
  background-color: #fca06a;
  &:hover {
    background-color: #fa7c33;
  }
}
@keyframes change-background {
  0% {
    background: #c2f1df;
  }
  100% {
    background: #d0bbe9;
  }
}
</style>
