const dictionary = {
  QUALIFICATION: {
    NOT_SPECIFIED: "Не выбрано",
    LEAD: "Lead",
    SENIOR: "Senior",
    MIDDLE: "Middle",
    JUNIOR: "Junior",
  },
  EN_LEVELS: {
    NEWBIE: "A0",
    NEWBIE_PLUS: "A0+",
    BEGINNER: "A1",
    BEGINNER_PLUS: "A1+",
    ELEMENTARY: "A2",
    ELEMENTARY_PLUS: "A2+",
    INTERMEDIATE: "B1",
    INTERMEDIATE_PLUS: "B1+",
    UPPER_INTERMEDIATE: "B2",
    UPPER_INTERMEDIATE_PLUS: "B2+",
    ADVANCED: "C1",
    ADVANCED_PLUS: "C1+",
    PROFICIENT: "C2",
  },
};

export default {
  install(Vue) {
    Vue.prototype.$localView = function (path) {
      return path.split(".").reduce((prev, key) => prev[key] || {}, dictionary);
    };
    Vue.prototype.$getLocalEnum = function (name, formatter) {
      return formatter ? formatter(dictionary[name]) : dictionary[name];
    };
  },
};
