import api from "../api";

const reportsStore = {
  namespaced: true,
  state: {
    changeFieldModalData: null,
  },
  getters: {},
  mutations: {},
  actions: {
    async uploadProjectReport({ dispatch }, { monthID, projectID }) {
      try {
        return await api.reports.projectReport(monthID, projectID);
      } catch (e) {
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение отчета`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async uploadAllReports({ dispatch }, monthID) {
      try {
        return await api.reports.allProjectsReport(monthID);
      } catch (e) {
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение отчета`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async uploadConsolidatedReport({ dispatch }, monthID) {
      try {
        return await api.reports.consolidatedReport(monthID);
      } catch (e) {
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение отчета`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async uploadPmProjectReport({ dispatch }, { since, until, project_id }) {
      try {
        return await api.reports.pmProjectReport(since, until, project_id);
      } catch (e) {
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение отчета`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
  },
};

export default reportsStore;
