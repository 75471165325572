import api from "../api";
import { numberToTime } from "@/assets/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

let isCheckTimezone = false;

const userStore = {
  namespaced: true,
  state: {
    me: null,
  },
  getters: {
    getGroupsString: (state) => {
      return state.me.groups.join(", ");
    },
    getUsername: (state) => {
      return state.me ? `${state.me.first_name} ${state.me.last_name}` : "";
    },
    getMe: (state) => {
      return state.me;
    },
    getMyTimezone: (state, getters, rootState) => {
      const tzList = rootState.timeZones;
      const currentTZ = tzList.find(
        (element) => element.timezone === state.me?.tz
      );
      if (!currentTZ) {
        return null;
      }
      const isPositive = currentTZ.gtm_diff.slice(0, 1) === "+";
      const [hours, minutes] = currentTZ.gtm_diff.slice(1).split(":");
      return { isPositive, hours, minutes };
    },
    getMyDate: (state) => {
      return state.me?.tz ? dayjs().tz(state.me.tz) : dayjs();
    },
    getFormattedDate: (state, getters) => (format) => {
      return getters.getMyDate.format(format);
    },
    isInGroup: (state) => (groups) => {
      return state.me?.groups.some((group) => groups.includes(group));
    },
  },
  mutations: {
    setMe(state, value) {
      state.me = value;
    },
  },
  actions: {
    async loadUser({ commit, dispatch, rootState }) {
      try {
        const response = await api.users.me();
        commit("setMe", response);
        if (!isCheckTimezone) {
          isCheckTimezone = true;

          const machineTZName = dayjs.tz.guess(); // название локации
          let machineTZDiff = dayjs.tz().$offset / 60;
          machineTZDiff = numberToTime(machineTZDiff); // смещение по времени
          const profileTZ = rootState.timeZones.find(
            (item) => item.timezone === response.tz
          ); // получаем детали локации из профиля
          if (profileTZ && profileTZ.gtm_diff === machineTZDiff) {
            return; // если смещение по времени в профиле и на клиенте совпадает
          }
          const foundedTZ =
            rootState.timeZones.find(
              (item) => item.timezone === machineTZName
            ) || // если нет совпадений по имени
            rootState.timeZones.find((item) => item.gtm_diff === machineTZDiff); // устанавливаем TZ с тем же смещением
          await dispatch("patchUser", { tz: foundedTZ.timezone });
        }
      } catch (e) {
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение данных пользователя`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
        throw new Error("Ошибка сети. Не удалось получить данные.");
      }
    },
    async patchUser({ dispatch }, params = {}) {
      try {
        await api.users.patchMe(params);
        await dispatch("loadUser");
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Обновление данных пользователя`,
            message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
  },
};

export default userStore;
