<template>
  <div class="navbar">
    <ul class="navbar-container">
      <li
        v-for="(item, index) of nav"
        :key="item.id + index"
        class="navbar-container__item"
        :class="item.classes"
        @mousedown="item.handler ? clickMenu(item.handler) : null"
      >
        <router-link :to="item.to">{{ item.title }}</router-link>
      </li>
    </ul>
    <div class="navbar__username">{{ getUsername }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { removeToken } from "../assets/utils";
import { ROLES } from "@/assets/enums";
import accessCheck from "@/router/routingRolesRules";

export default {
  name: "navbar",
  data() {
    return {};
  },
  methods: {
    clickMenu(handler) {
      if (typeof handler === "function") {
        handler();
      }
    },
    updatePage() {
      if (this.$route.name === "vacation-schedule") {
        this.$router.go(0);
      }
    },
    exitHandler() {
      removeToken();
    },
  },
  computed: {
    ...mapGetters({
      getUsername: "userStore/getUsername",
      getMe: "userStore/getMe",
    }),
    isThisProd() {
      return location.hostname === "erp.satva.dev";
    },
    nav() {
      const routes = [
        {
          title: "Сотрудники",
          id: "employees",
          to: "/employees",
        },
        {
          title: "Проекты",
          id: "projects",
          to: "/projects",
        },
        {
          title: "Планирование",
          id: "planning",
          to: "/planning",
          roles: [ROLES.FOUNDER, ROLES.PM],
        },
        {
          title: "График отпусков",
          id: "vacation-schedule",
          to: `/vacation-schedule`,
          handler: this.updatePage,
        },
        {
          title: "Мои трудозатраты",
          id: "expenses",
          to: "/expenses",
        },
        {
          title: "Просмотр трудозатрат",
          id: "expenses-view",
          to: "/expenses-view",
          roles: [ROLES.FOUNDER, ROLES.PM, ROLES.HR],
        },
        {
          title: "Бухгалтерия",
          id: "bookkeeping",
          to: "/bookkeeping",
        },
        // {
        //   title: "Оргструктура",
        //   id: "company-structure",
        //   to: "/company-structure",
        // },
        {
          title: "Профиль",
          id: "profile",
          to: "/profile",
          classes: ["navbar-container__item--line"],
        },
        {
          title: "Настройки",
          id: "settings",
          to: "/settings",
        },
        {
          title: "Выйти",
          id: "exit",
          to: "/login",
          classes: ["navbar-container__item--exit"],
          handler: this.exitHandler,
        },
      ];
      return routes.filter((route) => {
        return (
          (!route.disabledInProd || !this.isThisProd) &&
          accessCheck(route.id, this.getMe?.groups || [])
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 180px;
  z-index: 25;
  grid-area: navbar;
  background-color: $erp-primary-bg-color;
  color: #ffffff;
  padding-top: 50px;
}

.navbar-container {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.navbar__username {
  margin-top: 25px;
  padding-left: 20px;
}

.navbar-container__item {
  position: relative;
  padding: 7px 20px;
}

.navbar-container__item--line:before {
  position: relative;
  height: 1px;
  width: 100%;
  display: block;
  content: "";
  background-color: gray;
  margin: 15px 0 5px;
}

.navbar-container__item--active {
  color: #fff7cb;
}

.navbar-container__item--disabled {
  cursor: default;

  a {
    color: #cad0d42e;
  }
}
</style>
