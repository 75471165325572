<template>
  <Modal :onAccept="onAccept" :onClose="onClose" :isOpen="!!changeFieldModalData" :title="getTitle">
    <div class="modal-fields">
      <div class="modal-fields__item" :key="field.id" v-for="(field, ind) in getBody">
        <input v-model="modalData.body[ind][fieldName]" @input.stop="(e) => onInputChange(e, ind)"  />
        <div class="modal-fields__item--hint" :class="{'modal-fields__item--hint-open': isValidateOn}">
          {{ !field[fieldName] ? 'Заполните поле' : '' }}
        </div>
      </div>
    </div>
    <div class="modal-fields__add-button" @click="addNewField">
      <PlusIcon />
    </div>
  </Modal>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import Modal from "./Modal";
import PlusIcon from "../icons/PlusIcon";
import {fieldNameByObject} from "../../assets/utils";

export default {
  components: {
    Modal,
    PlusIcon,
  },
  data() {
    return {
      modalData: {},
      formData: [],
      isValidateOn: false,
    }
  },
  methods: {
    onClose() {
      console.log("onClose");
      this.clearLocalFields();
      this.setChangeFieldModalData(null);
    },
    onAccept() {
      this.isValidateOn = true;
      console.log('onAccept');
    },
    clearLocalFields() {
      this.modalData = {};
      this.isValidateOn = false;
    },
    onInputChange(e, ind) {
      // this.modalData.body[ind][this.fieldName] = e.target.value;
      console.log(this.modalData.body[ind]);
    },
    addNewField() {
      const newModalData = {...this.modalData};
      const fields = [...newModalData.body];
      if (fields[fields.length - 1][this.fieldName]) {
        fields.push({id: Date.now(), [this.fieldName]: ''});
        newModalData.body = fields;
        this.modalData = newModalData;
      }
    },
    fieldNameByObject,
    ...mapMutations({
      setChangeFieldModalData: "modalStore/setChangeFieldModalData",
    }),
  },
  computed: {
    ...mapState({
      changeFieldModalData: (state) => state.modalStore.changeFieldModalData,
    }),
    ...mapGetters({
      getChangeFieldModalData: "modalStore/getChangeFieldModalData",
    }),
    getTitle() {
     return `Изменить поле "${this.modalData?.title}"`;
    },
    getBody() {
      return this.modalData?.body || [];
    },
    fieldName() {
      return !this.modalData.isEmpty && fieldNameByObject(this.modalData.body[0]);
    },
  },
  watch: {
    changeFieldModalData() {
      this.modalData = {...this.changeFieldModalData};
    }
  },
}
</script>

<style lang="scss"  scoped>
  @import "@/assets/css/modal.scss";

  .modal-fields {
    .modal-fields__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      padding: 4px;
      input {
        margin-right: 4px;
      }
      .modal-fields__item--hint {
        display: none;
        color: red;
      }
      .modal-fields__item--hint-open {
        display: block;
      }
    }
  }
  .modal-fields__add-button {
    cursor: pointer;
    width: max-content;
  }
</style>
