import api from "../api";
import Vue from "vue";
import Vuex from "vuex";
import authStore from "./auth";
import userStore from "./user";
import staffStore from "./staff";
import projectsStore from "./projects";
import companyStructureStore from "./companyStructure";
import employeeCalendarStore from "./employeeCalendar";
import productionCalendarStore from "./productionCalendar";
import planningsStore from "./plannings";
import modalStore from "./modal";
import expensesStore from "./expenses";
import messagesStore from "./messages";
import settingsStore from "./settings";
import reportsStore from "./reports";

let timezoneTicket = null;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authStore,
    userStore,
    staffStore,
    projectsStore,
    companyStructureStore,
    employeeCalendarStore,
    productionCalendarStore,
    planningsStore,
    modalStore,
    expensesStore,
    messagesStore,
    settingsStore,
    reportsStore,
  },
  state: {
    timeZones: [],
  },
  mutations: {
    setTimeZones(state, list) {
      state.timeZones = list;
    },
  },
  actions: {
    async loadTimeZones({ state, commit, dispatch }) {
      if (state.timeZones.length || timezoneTicket) {
        return;
      }
      try {
        timezoneTicket = api.productionCalendar.getTimeZones();
        const result = await timezoneTicket;
        commit("setTimeZones", result.tz);
      } catch (e) {
        dispatch("noAuth", e, { root: true });
      }
      timezoneTicket = null;
    },
  },
});
