import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Users {
  constructor() {
    Binder.bind(this, Users);
    this.apiService = new ApiService("/api/users/me");
  }

  me() {
    return this.apiService.get();
  }

  patchMe(params) {
    return this.apiService.patch("", {}, params);
  }
}

export default new Users();
