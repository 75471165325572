import ApiService from "../apiService";
import Binder from "../../assets/binder";

class CompanyStructure {
  constructor() {
    Binder.bind(this, CompanyStructure);
    this.apiService = new ApiService("/api/company-structure");
  }

  organizationsList(params) {
    return this.apiService.get("organization", params);
  }

  organizationById({ id }) {
    return this.apiService.get(`organization/${id}`);
  }
}

export default new CompanyStructure();
