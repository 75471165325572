import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Plannings {
  constructor() {
    Binder.bind(this, Plannings);
    this.apiService = new ApiService("/api/plannings");
  }

  list(params) {
    return this.apiService.get("", { ...params });
  }

  createPlanning() {
    return this.apiService.post("", "", {});
  }

  getProjectsByMonth(monthId, isProduction, isCommercial) {
    return this.apiService.get("projects/project-management", {
      month_id: monthId,
      limit: 999,
      production: isProduction,
      commercial: isCommercial,
    });
  }

  getStaffActivityByMonth(monthId, params) {
    return this.apiService.get("staff-activity", {
      month_id: monthId,
      ...params,
    });
  }

  activitiesList(params) {
    return this.apiService.get("activities", params);
  }

  getProjectActivities(projectId, monthId) {
    return this.apiService.get(`projects/${projectId}/activities`, {
      month_id: monthId,
    });
  }

  getProjectInfo(projectId, monthId) {
    return this.apiService.get(`projects/${projectId}/project-management`, {
      month_id: monthId,
    });
  }

  createActivity(projectId, monthId, activityId) {
    return this.apiService.post(`projects/${projectId}/assignments`, "", {
      activity: activityId,
      month: monthId,
    });
  }

  deleteActivity(projectId, activityId) {
    return this.apiService.delete(
      `projects/${projectId}/assignments/${activityId}`
    );
  }

  createSubactivity(projectId, activityId, params = {}) {
    const { profile, plan_hours, lead } = params;
    return this.apiService.post(
      `projects/${projectId}/assignments/${activityId}/sub-assignments`,
      "",
      {
        profile,
        plan_hours,
        lead,
      }
    );
  }

  changeSubactivity(projectId, activityId, subactivityId, params = {}) {
    const { profile, plan_hours, lead } = params;
    return this.apiService.patch(
      `projects/${projectId}/assignments/${activityId}/sub-assignments/${subactivityId}`,
      "",
      {
        profile,
        plan_hours,
        lead,
      }
    );
  }

  changeStaffActivity({ id, available_hours, comment }) {
    return this.apiService.patch(`employee-months/${id}`, "", {
      available_hours,
      comment,
    });
  }

  changeStaffDayActivity({ id, available_hours }) {
    return this.apiService.patch(
      `employee-months/${id}/day-available-hours`,
      "",
      { available_hours }
    );
  }

  deleteSubactivity(projectId, activityId, subactivityId) {
    return this.apiService.delete(
      `projects/${projectId}/assignments/${activityId}/sub-assignments/${subactivityId}`
    );
  }

  deleteMonthlyPlanning(id) {
    return this.apiService.delete(`${id}`);
  }

  checkAvailableEmployeePlanningsList(sub_assignment_id, isDeletion) {
    return this.apiService.get(
      `employee-sub-assignments/${sub_assignment_id}`,
      {
        deletion: isDeletion,
      }
    );
  }

  changeEmployeePlanningsList(sub_assignment_id, isDeletion, month_ids) {
    return this.apiService.post(
      `employee-sub-assignments/${sub_assignment_id}`,
      "",
      {
        deletion: isDeletion,
        month_ids,
      }
    );
  }

  summaryActivity(params) {
    return this.apiService.get("summary-staff-activity", params);
  }
  
}

export default new Plannings();
