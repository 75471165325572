import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Projects {
    constructor() {
        Binder.bind(this, Projects);
        this.apiService = new ApiService("/api/projects");
    }
    list(params) {
        return this.apiService.get("", params);
    }

    listByMonth(params) {
        return this.apiService.get("active-projects", params);
    }
}

export default new Projects();
