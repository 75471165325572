import Vue from "vue";
import VueRouter from "vue-router";
import { JWT_KEY, DEFAULT_TITLE } from "@/assets/constants";
import accessCheck from "@/router/routingRolesRules";
import store from "@/store";
import { retrier } from "@/assets/utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/employees",
    name: "employees",
    component: () => import("@/pages/employees/index.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Сотрудники",
    },
  },
  {
    path: "/employees/:id",
    name: "employee-info",
    component: () => import("@/pages/employees/id.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Сотрудники",
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("@/pages/projects/index.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Проекты",
    },
  },
  {
    path: "/planning",
    name: "planning",
    component: () => import("@/pages/planning/create-planning.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Планирование",
    },
  },
  {
    path: "/planning/:year-:month",
    name: "monthly-planning",
    component: () => import("@/pages/planning/date.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Планирование",
    },
  },
  {
    path: "/expenses",
    name: "myExpenses",
    component: () => import("@/pages/expenses/index.vue"),
    beforeEnter: (to, from, next) => {
      store.commit("expensesStore/restoreFirstColumnWidth");
      next();
    },
    meta: {
      layout: "main-layout",
      title: "ERP - Мои трудозатраты",
    },
  },
  {
    path: "/expenses-view",
    name: "expensesView",
    component: () => import("@/pages/expenses/expensesView.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Трудозатраты",
    },
  },
  {
    path: "/company-structure",
    name: "company-structure",
    component: () => import("@/pages/company-structure.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Оргструктура",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/pages/profile.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Профиль",
    },
  },
  {
    path: "/bookkeeping",
    name: "bookkeeping",
    component: () => import("@/pages/bookkeeping.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Бухгалтерия",
    },
  },
  {
    path: "/vacation-schedule/:year",
    name: "vacation-schedule",
    component: () => import("@/pages/vacation-schedule.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - График отпусков",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/pages/settings.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - Настройки",
    },
  },
  {
    path: "/login",
    name: "auth",
    component: () => import("@/pages/auth.vue"),
    meta: {
      layout: "secondary-layout",
      title: "ERP - Login",
    },
  },
  {
    path: "/page-not-found",
    name: "not-found",
    alias: "*",
    component: () => import("@/pages/error.vue"),
    meta: {
      layout: "main-layout",
      title: "ERP - 404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "navbar-container__item--active",
  routes,
});

const loadUser = retrier(
  async () => {
    return await store.dispatch("userStore/loadUser");
  },
  {
    maxRetries: 10,
    successHandler: () => true,
    failHandler: () => false,
  }
);

router.beforeEach(async (to, from, next) => {
  if (to.fullPath === from.fullPath && to.name === from.name) {
    next(false);
    return;
  }

  const hasJwt = !!localStorage.getItem(JWT_KEY);
  if (to.name !== "auth" && !hasJwt) {
    next({ name: "auth" });
  } else {
    if (!store.getters["userStore/getMe"] && to.name !== "auth") {
      if (from.name === "not-found") {
        next(false);
        return;
      }
      await store.dispatch("loadTimeZones");
      const isSuccess = await loadUser();
      if (!isSuccess) {
        next({ name: "not-found" });
        return;
      }
    }

    const date =
      to.params.year && to.params.month
        ? ` ${
            to.params.month > 9 ? to.params.month : "0" + to.params.month
          }.${to.params.year.slice(-2)}`
        : "";
    document.title = to.meta.title + date || DEFAULT_TITLE;

    if (to.path === "/") {
      next({ name: "employees" });
      return;
    }

    if (to.path === "/vacation-schedule") {
      const year = store.getters["userStore/getMyDate"].year();
      next({ name: "vacation-schedule", params: { year } });
      return;
    }

    if (to.name === "auth" && store.getters["userStore/getMe"]) {
      store.commit("userStore/setMe", null);
    }

    if (!accessCheck(to.name, store.getters["userStore/getMe"]?.groups || [])) {
      next({ name: "not-found" });
      return;
    }
    next();
  }
});

export default router;
