import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Jwt {
  constructor() {
    Binder.bind(this, Jwt);
    this.apiService = new ApiService("/api/auth");
  }

  getToken({ email, password }) {
    return this.apiService.post("jwt", {}, { email, password });
  }

  refreshToken(refreshToken) {
    return this.apiService.post("jwt/refresh", {}, { refresh: refreshToken });
  }

  resetPassword(email) {
    return this.apiService.post("reset-password", {}, { email });
  }

  setPassword({ email, code, password }) {
    return this.apiService.post(
      "reset-password/confirm",
      {},
      { email, code, password }
    );
  }
}

export default new Jwt();
