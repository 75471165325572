import api from "../api";
import { removeObjectKeys, isNameMatch } from "@/assets/utils";
import { TABLE_LIST_LIMIT, QUALIFICATIONS_LIST } from "@/assets/constants";
import { debounce } from "@/assets/utils";

let lastStaffRequestTime = null;

const staffStore = {
  namespaced: true,
  state: {
    pageNumber: 1,
    currentStaff: {},
    staffList: [],
    staffData: null,
    qualsList: QUALIFICATIONS_LIST,
    positionList: [],
    qualFilter: [],
    positionFilter: [],
    search: "",
  },
  getters: {
    fullName: (state) => (staff) => {
      let employee;
      if (staff) {
        employee = staff;
      } else {
        employee = state.currentStaff;
      }
      if (!employee) return false;
      let fullName = "";
      const keys = ["last_name", "first_name"];
      keys.forEach((key) => {
        if (employee[key]) {
          fullName += employee[key] + " ";
        }
      });
      return fullName.trimEnd();
    },
    filteredStaffList: (state, getters) => {
      const inputName = state.search;
      const selectedQuals = state.qualFilter;
      const selectedPositions = state.positionFilter;
      const qualsNumber = getters.qualsList.length;
      const positionNumber = getters.positionList.length;
      return state.staffList.filter((staff) => {
        const { last_name, first_name, qualification, position } = staff;
        let isStaffNameMatch = true;
        let isQualMatch = true;
        let isPositionMatch = true;
        if (state.search.length > 2) {
          isStaffNameMatch = isNameMatch({
            first_name,
            last_name,
            input_text: inputName,
          });
        }
        if (selectedQuals.length < qualsNumber && selectedQuals.length !== 0) {
          isQualMatch = selectedQuals.includes(qualification);
        }
        if (
          selectedPositions.length < positionNumber &&
          selectedPositions.length !== 0
        ) {
          isPositionMatch = selectedPositions.includes(position.role);
        }
        return isStaffNameMatch && isQualMatch && isPositionMatch;
      });
    },
  },
  mutations: {
    setQualsList(state, list) {
      if (state.qualsList.length) {
        return;
      }
      state.qualsList = list.reduce((acc, value) => {
        if (!acc.find((item) => item.id === value.qualification)) {
          acc.push({ label: value.qualification, id: value.qualification });
        }
        return acc;
      }, []);
    },
    setPositionList(state, list) {
      if (state.positionList.length) {
        return;
      }
      state.positionList = [...list]
        .sort((a, b) => a.role_sort - b.role_sort)
        .reduce((acc, value) => {
          if (!acc.find((item) => item.id === value.position.id)) {
            acc.push({ label: value.position.role, id: value.position.id });
          }
          return acc;
        }, []);
    },
    setPageNumber(state, value) {
      state.pageNumber = value;
    },
    setCurrentStaff(state, value) {
      state.currentStaff = value;
    },
    setStaffList(state, value) {
      state.staffList = value;
    },
    setStaffData(state, value) {
      state.staffData = value;
    },
    setSearch(state, string) {
      state.search = string;
    },
    clearSearch(state) {
      state.search = "";
    },
    setQualFilter(state, qualsList) {
      state.qualFilter = qualsList;
    },
    clearQualFilter(state) {
      state.qualFilter = [];
    },
    setPositionFilter(state, positionsList) {
      state.positionFilter = positionsList;
    },
    clearPositionFilter(state) {
      state.positionFilter = [];
    },
  },
  actions: {
    async loadStaffList({ state, commit, dispatch }, params) {
      try {
        const currentTime = Date.now();
        lastStaffRequestTime = currentTime;
        const positions = state.positionFilter
          .filter((position) => position)
          .join(",");
        const qualifications = state.qualFilter
          .filter((qual) => qual)
          .join(",");
        const response = await api.staff.list({
          offset: (state.pageNumber - 1) * TABLE_LIST_LIMIT,
          limit: params?.limit ?? TABLE_LIST_LIMIT,
          search: state.search,
          position__in: positions,
          qualification__in: qualifications,
        });
        if (lastStaffRequestTime !== currentTime) {
          return;
        }
        commit("setStaffList", response.results);
        commit("setStaffData", removeObjectKeys(response, "results"));
        commit("setQualsList", response.results);
        commit("setPositionList", response.results);
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка сотрудников`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async setPageNumber({ commit, dispatch }, pageNumber) {
      commit("setPageNumber", pageNumber);
      await dispatch("loadStaffList");
    },
    async loadCurrentStaff({ commit, dispatch }, id) {
      try {
        const response = await api.staff.one(id);
        commit("setCurrentStaff", response);
        return response;
      } catch (e) {
        dispatch("noAuth", e, { root: true });
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение информации о сотруднике`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
        return { success: false };
      }
    },
    setSearch: debounce(async function ({ commit, dispatch }, text) {
      commit("setSearch", text);
      await dispatch("loadStaffList");
    }),
    async setPositionFilter({ commit, dispatch }, list) {
      commit("setPositionFilter", list);
      await dispatch("loadStaffList");
    },
    async setQualFilter({ commit, dispatch }, list) {
      commit("setQualFilter", list);
      await dispatch("loadStaffList");
    },
    async createEmployee({ dispatch }, data) {
      try {
        await api.staff.create(data);
      } catch (e) {
        console.error(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Добавление нового сотрудника`,
            message: `Ошибка сети. Не удалось создать нового сотрудника.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async updateEmployee({ dispatch }, { id, fields }) {
      try {
        await api.staff.update(id, fields);
      } catch (e) {
        console.error(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Изменение данных сотрудника`,
            message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
  },
};

export default staffStore;
