import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/filters";
import "@/assets/plugins";
import local from "@/assets/local";
import MainLayout from "./layouts/main-layout";
import Secondary from "./layouts/secondary-layout";
import FlatPickr from "vue-flatpickr-component"; // https://github.com/ankurk91/vue-flatpickr-component
import "flatpickr/dist/flatpickr.css";
import "@/assets/css/core.scss";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

window.addEventListener("unhandledrejection", function (event) {
  store.dispatch("messagesStore/setMessage", {
    message: event.promise,
    info: event.reason,
    type: "error",
  });
});

window.addEventListener("beforeunload", () => {
  // очищаем запущенный таймер при перезагрузке
  if (store.state.expensesStore.isTimerActive) {
    clearInterval(store.state.expensesStore.timer.timerIntervalId);
  }
});

// *************
// ** $pushMessage - глобальный метод для добавления всплывающих сообщений
// **********
// API { title?, message?, location?, app?, lifeTime?, type? = "info", withoutDelay? = false }:
/// title        <string>  - заголовок модального окна (только для сообщений с type="info")
/// message      <string>  - сообщение, выводимое в теле модального окна
/// type         <string>  - тип сообщения [ info | warn | error ]
/// location     <string>  - отображается только в консоли, указывает на место возникновения сообщения
/// app          <object>  - компонент,в котором произошла ошибка
/// lifeTime     <number>  - время жизни модального окна в секундах. По умолчанию берется глобальное значение из settingsStore.infoModalLifeTime
/// withoutDelay <boolean> - если передать значение true, то появится возможность выводить одинаковые сообщения (согласно ТЗ одинаковые сообщения об ошибках не выводятся одновременно, данный флаг снимает это ограничение)
Vue.prototype.$pushMessage = (params) =>
  store.dispatch("messagesStore/setMessage", params);

Vue.config.errorHandler = function (message, vm, info) {
  store.dispatch("messagesStore/setMessage", {
    message,
    location: info,
    app: vm,
    type: "error",
  });
};
Vue.config.productionTip = false;

Vue.component("main-layout", MainLayout);
Vue.component("secondary-layout", Secondary);
Vue.component("flat-pickr", FlatPickr);

const DSN =
  "https://ea6009177157485d8be347ef9c8ba76f@o1261685.ingest.sentry.io/6440054";

const environment = location.hostname.startsWith("dev")
  ? "develop"
  : "production";

Sentry.init({
  Vue,
  dsn: DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["erp.satvaspace.tech", "dev.erp.satvaspace.tech"],
    }),
  ],
  tracesSampleRate: 1.0,
  logErrors: true,
  environment,
});

Vue.use(local);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
