export const ROLES = {
  BOOKKEEPER: "BOOKKEEPER",
  FOUNDER: "FOUNDER",
  HR: "HR",
  PM: "PM",
  PMO: "PMO",
  STAFF: "STAFF",
  TM: "TM",
};

export const EN_LEVELS = {
  NEWBIE: "A0",
  NEWBIE_PLUS: "A0+",
  BEGINNER: "A1",
  BEGINNER_PLUS: "A1+",
  ELEMENTARY: "A2",
  ELEMENTARY_PLUS: "A2+",
  INTERMEDIATE: "B1",
  INTERMEDIATE_PLUS: "B1+",
  UPPER_INTERMEDIATE: "B2",
  UPPER_INTERMEDIATE_PLUS: "B2+",
  ADVANCED: "C1",
  ADVANCED_PLUS: "C1+",
  PROFICIENT: "C2",
};

export const QUALIFICATION = {
  NOT_SPECIFIED: "NOT_SPECIFIED",
  LEAD: "LEAD",
  SENIOR: "SENIOR",
  MIDDLE: "MIDDLE",
  JUNIOR: "JUNIOR",
};

export const DAY_TYPES = {
  WORKING: "WORKING",
  DAY_OFF: "DAY_OFF",
  SHORTENED: "SHORTENED",
  VACATION: "VACATION",
  SICK: "SICK",
};

export const BUTTON_THEMES = {
  common: "common",
  icon: "icon",
  hoverIcon: "hoverIcon",
  label: "label",
  modal: "modal",
  auth: "auth",
};

export const BUTTON_COLORS = {
  accept: "accept",
  reset: "reset",
  warning: "warning",
};
