import ApiService from "../apiService";
import Binder from "../../assets/binder";

class Expenses {
  constructor() {
    Binder.bind(this, Expenses);
    this.apiService = new ApiService("api/task-tracker");
  }

  list(params) {
    return this.apiService.get("my-tasks", { ...params });
  }

  viewList(params) {
    return this.apiService.get("workload", { ...params });
  }

  employeesList(params) {
    return this.apiService.get("workload-employees", { limit: 999, ...params });
  }

  projectsList(params) {
    return this.apiService.get("workload-projects", { ...params });
  }

  staffList(params) {
    return this.apiService.get("workload-staff", {
      limit: 999,
      ...params,
    });
  }

  monthlyList(params) {
    return this.apiService.get("my-monthly-tasks", {
      month_id: "8",
      ...params,
    });
  }

  createNewTask({ name, sub_assignment }) {
    return this.apiService.post("my-tasks", {}, { name, sub_assignment });
  }

  changeTask({ name, id }) {
    return this.apiService.put(`my-tasks/${id}`, {}, { name });
  }

  patchTask({ name, id }) {
    return this.apiService.patch(`my-tasks/${id}`, {}, { name });
  }

  deleteTask({ id }) {
    return this.apiService.delete(`my-tasks/${id}`, {}, {});
  }

  createTimeEntries({ actual_hours = 0, pc_day, task }) {
    return this.apiService.post(
      "my-time-entries",
      {},
      { actual_hours, pc_day, task }
    );
  }

  changeTimeEntries({ actual_hours = 0, id }) {
    return this.apiService.put(`my-time-entries/${id}`, {}, { actual_hours });
  }

  patchTimeEntries({ actual_hours = 0, id }) {
    return this.apiService.patch(`my-time-entries/${id}`, {}, { actual_hours });
  }

  deleteTimeEntries({ id }) {
    return this.apiService.delete(`my-time-entries/${id}`, {}, {});
  }

  getProjectsListForEmployee(month_id) {
    return this.apiService.get("workload-projects", { month_id });
  }
}

export default new Expenses();
