<template>
  <div ref="modal-proto" class="modal" v-if="isOpen" :style="position">
    <div :class="{ modal__overlay: data.hasOverlay }" @click="close"></div>
    <div class="modal-elem" :class="{ 'modal-elem--center': isCenter }">
      <div class="modal__header" :class="headerClasses">
        {{ data.title }}
      </div>
      <div class="modal__body modal-scroll">
        <slot></slot>
      </div>
      <div
        v-if="data.actions.length"
        class="modal__actions"
        :class="actionsClasses"
      >
        <Button
          v-for="(button, buttonIndex) of data.actions"
          :key="buttonIndex"
          theme="modal"
          :color="button.id"
          @click="button.handler"
          :isLoading="button.isLoading"
        >
          {{ button.title }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "ModalProto",
  components: { Button },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    showStateHandler: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      isOpen: false,
      position: {
        top: null,
        left: null,
      },
      isCenter: false,
    };
  },
  computed: {
    headerClasses() {
      return this.data.classes?.header;
    },
    actionsClasses() {
      return this.data.classes?.actions;
    },
  },
  watch: {
    isOpen(value) {
      if (this.showStateHandler) {
        this.showStateHandler(value);
      }
      if (!value) {
        this.$emit("close");
        this.position = {
          top: null,
          left: null,
        };
      } else {
        this.$emit("open");
      }
    },
  },
  beforeDestroy() {
    document.body.style["overflow"] = "auto";
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    open(isScrollBlocked = true) {
      this.isOpen = true;
      if (isScrollBlocked) {
        document.body.style["overflow"] = "hidden";
      }
    },
    close() {
      this.isOpen = false;
      document.body.style["overflow"] = "auto";
    },
    setPosition(coords) {
      setTimeout(() => {
        if (coords === "center") {
          this.isCenter = true;
        } else {
          let { top, left } = coords;
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const { width: modalWidth, height: modalHeight } =
            this.$refs["modal-proto"]?.getBoundingClientRect();
          if (windowHeight < top + modalHeight) {
            top = windowHeight - modalHeight;
          }
          if (windowWidth - left - modalWidth < 0) {
            left = windowWidth - modalWidth - 40;
          }
          this.position = {
            top: `${top}px`,
            left: `${left}px`,
          };
        }
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";
</style>
