import api from "../api";
import { removeObjectKeys } from "@/assets/utils";
import { TABLE_LIST_LIMIT, PROJECTS_STATUSES } from "@/assets/constants";
import { debounce } from "@/assets/utils";

let lastRequestTime = null;

const projectsStore = {
  namespaced: true,
  state: {
    projectsList: [],
    projectsData: null,
    projectsByMonth: null,
    statusFilter: PROJECTS_STATUSES,
    pageNumber: 1,
    search: "",
    filterTypes: ["customer", "name", "status"],
    findProject: {},
    error: "",
  },
  getters: {
    filteredProjectsList: (state) => {
      const search = state.search.trim().toLowerCase();
      if (search.length < 3) {
        return state.projectsList;
      } else {
        return state.projectsList.filter((project) => {
          return state.filterTypes.find((filterType) => {
            let projectFields = project[filterType];
            if (!projectFields || projectFields.length === 0) {
              return false;
            }
            if (typeof projectFields === "string") {
              return projectFields.toLowerCase().includes(search);
            }
            if (typeof projectFields === "object") {
              if (!Array.isArray(projectFields)) {
                projectFields = [projectFields];
              }
              return projectFields.find((projectField) => {
                return Object.keys(projectField).find((key) => {
                  return (
                    key !== "id" &&
                    projectField[key].toLowerCase().includes(search)
                  );
                });
              });
            }
            console.warn("необработанный тип данных при поиске");
            return false;
          });
        });
      }
    },
  },
  mutations: {
    setProjectsList(state, projects) {
      state.projectsList = projects;
    },
    setProjectsData(state, value) {
      state.projectsData = value;
    },
    setProjectsByMonth(state, projects) {
      state.projectsByMonth = projects;
    },
    setStatusFilter(state, value) {
      state.statusFilter = value;
    },
    refreshStatusFilter(state) {
      state.statusFilter = PROJECTS_STATUSES;
    },
    setPageNumber(state, value) {
      state.pageNumber = value;
    },
    setSearch(state, value) {
      state.search = value;
    },
    setProjectsError(state, value) {
      state.error = value;
    },
    setFindProject(state, value) {
      state.findProject = value;
    },
  },
  actions: {
    async loadProjectsList({ state, commit, dispatch }) {
      try {
        const currentTime = Date.now();
        lastRequestTime = currentTime;
        const statuses = state.statusFilter
          .filter((status) => status)
          .join(",");
        const response = await api.projects.list({
          offset: (state.pageNumber - 1) * TABLE_LIST_LIMIT,
          limit: TABLE_LIST_LIMIT,
          search: state.search,
          statuses__status__in: statuses,
        });
        if (lastRequestTime !== currentTime) {
          return;
        }
        commit("setProjectsList", response.results);
        commit("setProjectsData", removeObjectKeys(response, "results"));
      } catch (e) {
        console.log("Catch loadProjectsList");
        // dispatch("noAuth", e, { root: true });
        commit("setProjectsError", "нет доступа к таблице");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка проектов`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadProjectsListByMonth({ commit, dispatch }, monthID) {
      try {
        const currentTime = Date.now();
        lastRequestTime = currentTime;
        const response = await api.projects.listByMonth({ month_id: monthID });
        if (lastRequestTime !== currentTime) {
          return;
        }
        commit("setProjectsByMonth", response.results);
      } catch (e) {
        console.log("Catch loadProjectsListByMonth");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка проектов за месяц`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async setPageNumber({ commit, dispatch }, pageNumber) {
      commit("setPageNumber", pageNumber);
      await dispatch("loadProjectsList");
    },
    async findProject({ commit, dispatch }, params = {}) {
      try {
        const response = await api.projects.list(params);
        commit("setFindProject", response.results);
        return response.results;
      } catch (e) {
        console.log("Catch findProject");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка проектов`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    setSearch: debounce(async function ({ commit, dispatch }, text) {
      commit("setSearch", text);
      await dispatch("loadProjectsList");
    }),
    async setStatusFilter({ commit, dispatch }, list) {
      commit("setStatusFilter", list);
      await dispatch("loadProjectsList");
    },
  },
};

export default projectsStore;
